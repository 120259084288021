import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cancel-message',
  templateUrl: './cancel-message.component.html',
  styleUrls: ['./cancel-message.component.css'],
})
export class CancelMessageComponent implements OnInit {
  constructor(private route: Router) {}

  ngOnInit(): void {}
  backtoHome() {
    this.route.navigate(['dashboard']);
  }
}
