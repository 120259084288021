<mat-card appearance="outlined" class="view-page mat-card mat-focus-indicator p-0 mx-3">
    <div class="form-field-full">
        <div class="card">

            <div class="card-body">
                <div class="toolbar-nav">
                    <div class="row">
                        <div class="col-12 col-lg-7 col-xl-7 col-md-7 p-0 col-sm-6">
                            <h1 class="title">View Event Participants</h1>
                        </div>
                        <div class="col-12 col-md-5 col-lg-5 col-xl-5 col-sm-6 text-right col-sm-6 mb-2">
                            <button class="btn btn-primary mr-3 text-right" (click)="exportexcel()"
                                *ngIf="showExport">Export <i *ngIf="loadFlag2"
                                    class="fa fa-spinner fa-pulse loader mt-1 ml-1"></i></button>
                            <button class="btn btn-primary" [routerLink]="['/all-events/events']" name="back"><i
                                    class="fa fa-arrow-left mr-2" aria-hidden="true"></i>Back</button>
                        </div>
                        <div class="container">
                            <!-- Button to open modal -->

                            <!-- Modal -->
                            <div class="modal" *ngIf="isModalOpen">
                                <div class="modal-content">
                                    <button class="closeButton  mb-2 mt-2" (click)="closeModal()"><i
                                            class="fa fa-close mr-2" aria-hidden="true"></i></button>

                                    <!-- QR code scanner component -->
                                    <ngx-scanner-qrcode #action="scanner" [config]="config"
                                        (event)="onEvent($event, action)"></ngx-scanner-qrcode>
                                    <p *ngIf="action.isLoading">⌛ Loading...</p>

                                    <button id="scanQRButton" (click)="action.isStart ? action.stop() : action.start()">
                                        {{ action.isStart ? 'Stop' : 'Scan QR' }}
                                    </button>
                                    <label for="fileInput" class="custom-file-upload">
                                        Select QR Code from your files
                                    </label>
                                    <input #file type="file" (change)="onSelects(file.files)"
                                        accept=".jpg, .png, .gif, .jpeg" />
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-space-between">
                            <div class="col-lg-4 col-xl-4 col-md-8 col-sm-12 col-12 form-group has-search">
                                <span class="fa fa-search form-control-feedback"></span>
                                <input type="search"
                                    class="form-control search-bar col-xl-12 col-lg-12 col-md-12 col-12 py-2"
                                    [(ngModel)]="searchText" (input)="searchHits.next(true)" placeholder="Search" />
                                <button class="btn btn-secondary mt-2" (click)="openModal()">Open QR Scanner
                                    Modal</button>

                            </div>

                        </div>
                        <mat-tab-group (selectedTabChange)="onTabChange($event)" class="">

                            <ng-template #showLoader>
                                <div class="text-center loader">
                                    <mat-progress-spinner diameter=50 style="margin: 0 auto" mode="indeterminate"
                                        color="accent">
                                    </mat-progress-spinner>
                                </div>
                            </ng-template>

                            <mat-tab label="Event Participants">

                                <mat-card appearance="outlined" class="list p-0">
                                    <app-generic-table [rowData]="rowData" [enableCheckbox]="true"
                                        [allowMultiSelect]="true" [permission]="permissionsCheck"
                                        [sqPaginationConfig]="tablePaginationSettings"
                                        (getPagination)="getPageData($event)" (getActionTable)="getActions($event)"
                                        (getSorting)="sort($event)" [sqColumnDefinition]="columnDefinition"
                                        [length]="resultsLength"></app-generic-table>
                                </mat-card>
                            </mat-tab>

                        </mat-tab-group>
                    </div>
                </div>

            </div>
        </div>
    </div>
</mat-card>