import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-view-purpose-of-donation-dialog',
  templateUrl: './view-purpose-of-donation-dialog.component.html',
  styleUrls: ['./view-purpose-of-donation-dialog.component.css'],
})
export class ViewPurposeOfDonationDialogComponent implements OnInit {
  public typename: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private dialogRef: MatDialogRef<ViewPurposeOfDonationDialogComponent>,
  ) {
    if (this.dialogData) {
      this.typename = this.dialogData.data.type_name;
    }
  }

  ngOnInit(): void {}
  close() {
    this.dialogRef.close(true);
  }
}
