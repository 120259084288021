import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ApprovalDialogComponent } from '../dialogs/approval-dialog/approval-dialog.component';

import { MessageDialogComponent } from '../dialogs/message-dialog/message-dialog.component';
import { ViewDiscountDialogComponent } from '../dialogs/view-discount-dialog/view-discount-dialog.component';
import { DataManagerService } from '../services/DataManager/data-manager.service';
import { BaseListComponent } from '../shared/abstract/base-list.component';
import { AppConstants } from '../shared/constants/app-constants';
import { HeaderConstants } from '../shared/constants/header-constants';
import { PermissionConstants } from '../shared/constants/permission-constants';

@Component({
  selector: 'app-payment-receipts',
  templateUrl: './payment-receipts.component.html',
  styleUrls: ['./payment-receipts.component.css'],
})
export class PaymentReceiptsComponent
  extends BaseListComponent
  implements OnInit
{
  public viewData: Object;
  public discountUrl: string =
    AppConstants.BASE_URL + AppConstants.ADD_DISCOUNT;
  public deleteDiscount_url: string;
  public gridData: Object = [];
  public loged_user = JSON.parse(localStorage.getItem('user') || 'null');
  public schoolData: Object = [];

  constructor(
    dataManagerService: DataManagerService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {
    super();
    this.service = dataManagerService;
    this.page = 1;
    this.rows = 10;
    this.getTableSettings();
    this.searchHits = new Subject<void>();

    this.permissionsCheck = {
      edit_permission: false,
      delete_permission: false,
      view_permission: false,
      create_permission: true,
    };
    this.route.params.subscribe((params) => {
      // Access individual query parameters
      const paramName = params['id'];

      this.getListUrl =
        AppConstants.BASE_URL + '/get-payments-receipts/' + paramName;
    });
    this.columnDefinition = HeaderConstants.paymentReceiptHeader;
  }
  ngOnInit(): void {
    this.loader = true;
    // this.getGridData();
    this.searchHits.pipe(debounceTime(600)).subscribe((res) => {
      this.loader = true;
      if (!this.isNullOrUndefined(this.searchText)) {
        console.log('serch jsjsdf', this.setUrl());
      }
    });
    this.setUrl();
    //  throw new Error('Method not implemented.');
  }
  protected getListRes(res: any): void {
    this.loader = true;
    this.rowData = [];
    console.log(res);

    if (res['status'] == 'success') {
      this.resultsLength = res.data.total;

      res.data.data.forEach((element: any, index: number) => {
        element.downloadPdf = true;
        element.amount = '$' + element.amount;
        this.rowData.push(element);
      });
      this.loader = false;
    } else {
      this.loader = false;
    }
  }

  protected getGridData() {
    this.service.getAllDiscounts(this.getListUrl, {}).subscribe({
      next: (res: any) => {
        this.schoolData = res.data.data;
        console.log('====================');
        console.log(JSON.stringify(this.schoolData));
        console.log('====================');
        this.loader = false;
      },
    });
    console.log('====================');
    console.log(JSON.stringify(this.schoolData));
    console.log('====================');
  }
  protected getListErr(err: any): void {
    console.log(err);
    this.loader = false;
  }

  ngDoCheck() {
    if (location.pathname === '/all-discounts') {
      this.router.navigate(['/all-discounts/discount']);
    }
    if (this.create_permission) {
      // this.create = this.create_permission;
    }
  }

  getActions(data: any) {
    if (data.actions === 'downloadPDF') {
      const downloadPDFUrl =
        AppConstants.BASE_URL +
        '/download-payment-receipts/' +
        data.data.receiptName;
      const dialogRef = this.dialog.open(ApprovalDialogComponent, {
        data: {
          message: 'Do you want to download this Receipt?',
          url: downloadPDFUrl,
          data: data,
          buttonText: {
            ok: 'Proceed',
            cancel: 'Cancel',
          },
        },
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.router
            .navigateByUrl('home', { skipLocationChange: true })
            .then(() => {
              this.router.navigate([location.pathname], {
                relativeTo: this.route,
              });
            });
        }
      });
    }
  }
  goBack() {
    window.history.back();
  }
  ngOnDestroy(): void {}
}
