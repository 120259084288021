import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-view-expense-category-dialog',
  templateUrl: './view-expense-category-dialog.component.html',
  styleUrls: ['./view-expense-category-dialog.component.css'],
})
export class ViewExpenseCategoryDialogComponent implements OnInit {
  public cat_name: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private dialogRef: MatDialogRef<ViewExpenseCategoryDialogComponent>,
  ) {
    if (this.dialogData) {
      console.log(this.dialogData);
      this.cat_name = this.dialogData.data.cat_name;
    }
  }

  ngOnInit(): void {}
  close() {
    this.dialogRef.close(true);
  }
}
