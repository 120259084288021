import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataManagerService } from './services/DataManager/data-manager.service';
import { AppConstants } from './shared/constants/app-constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  sideNavFlag: boolean = false;
  title = 'NSICC-UI';
  public navUrl = window.location.pathname;
  public menuList: any;
  constructor(
    private spinner: NgxSpinnerService,
    private dataManagerService: DataManagerService,
    private router: Router,
    @Inject('LOCALSTORAGE') private localStorage: any,
    private route: ActivatedRoute,
  ) {
    this.sideNavFlag = this.localStorage.getItem('authToken') ? true : false;
    this.route.queryParams.subscribe((params: any) => {
      if (params['p_sts'] == 1) {
        this.dataManagerService.showSnackBar(
          'Payment Approved Successfully',
          'ok',
          3000,
        );
        const originalUrl: any = this.router.url.split('?')[0];
        this.router.navigate([originalUrl]);
      } else if (params['p_sts'] == 0) {
        this.dataManagerService.showSnackBar('Payment Declined', 'ok', 3000);
        const originalUrl: any = this.router.url.split('?')[0];
        this.router.navigate([originalUrl]);
      }
    });
  }
  ngOnInit(): void {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 2000);

    this.dataManagerService.permissionsArray = JSON.parse(
      this.localStorage.getItem(AppConstants.STORAGE_KEY_USER_PERMISSIONS) ||
        'null',
    );
  }
  refreshScreen() {
    this.dataManagerService.LoginStatus()
      ? console.log('accessTokenPresent')
      : this.router.navigate(['/login']);
  }
}
