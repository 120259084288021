<div class="p-0" *ngIf="toggleSideNav">
  <mat-drawer-container class="example-container" autosize>
    <mat-toolbar color="primary">
      <mat-toolbar-row class="d-flex">
        <div class="col-1 text-left pl-0">
          <!--        <button type="button" mat-button  class="">-->
          <span>
            <mat-icon (click)="drawer.toggle()" class="menu_tog">menu</mat-icon>
            <text class="h6">Menu</text>
          </span>
          <!--        </button>-->
        </div>
        <!-- <span fxflex="" style="flex: 1 1 0%; box-sizing: border-box;"></span> -->
        <div class="text-end col-11" [ngClass]="{'pr-4': menuList.name.length > 10, 'pr-0': menuList.name.length < 10}">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon class="">account_circle</mat-icon>
            <span>{{menuList.name && (menuList.name | titlecase)}}</span>
          </button>
          <mat-menu #menu="matMenu">
            <!-- <button mat-menu-item>
            <mat-icon>account_circle</mat-icon>
            <span>Profile</span>
          </button>
          <button mat-menu-item>
            <mat-icon>settings</mat-icon>
            <span>Settings</span>
          </button> -->
            <button mat-menu-item (click)="logout()">
              <mat-icon>exit_to_app</mat-icon>
              <span>Logout</span>
            </button>
          </mat-menu>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
    <mat-drawer #drawer class="example-sidenav" [opened]="menuOpen" [mode]="over">
      <div class="text-center my-3">
        <img src="../../../assets/login-logo.png" width="140px" class="text-center" />
      </div>
      <mat-nav-list class="side-navnar" *ngIf="menuList">
        <ng-container *ngFor="let menu of menuList.menu; index as i;">
          <ng-container [ngTemplateOutlet]="menu.subMenu != null ?  childMenu : parentMenu"
            [ngTemplateOutletContext]="{menu:menu}">
          </ng-container>
        </ng-container>
      </mat-nav-list>

      <ng-template #parentMenu let-menu="menu">
        <mat-list-item style="margin-top:10px !important; margin-bottom:10px !important" (click)="toggle()"
          [routerLink]="menu.routes === '/employee' ? menu.routes + '/employee-list' : menu.routes"
          [ngClass]="{'is-active': matchUrl(menu.routes)}" routerLinkActive="is-active" [routerLinkActiveOptions]="{exact:
            true}">
          <img width="20px" height="20px" class="mr-2"
            src="../../../assets/logo/{{menu.routes.length > 1 ? menu.routes.slice(1) : 'settings'}}.svg">
          <span style="font-size: 13px !important;">{{menu.name}}</span>
        </mat-list-item>
      </ng-template>
    </mat-drawer>
    <div class="example-sidenav-content">
      <router-outlet></router-outlet>
      <div class="text-center">
        <app-footer></app-footer>
      </div>
    </div>
  </mat-drawer-container>
  <mat-accordion>
    <ng-template #childMenu let-menu="menu">
      <mat-expansion-panel [class.mat-elevation-z0]="true">
        <mat-expansion-panel-header style="margin-top:5px !important; margin-bottom:5px !important"
          [ngClass]="{'is-active': matchUrl(menu.routes)}">
          <img width="20px" height="20px" class="mr-2" src="../../../assets/logo/{{menu.routes.slice(1)}}.svg">
          <span style="font-weight: 400;"> {{menu.name}}</span>
        </mat-expansion-panel-header>
        <mat-nav-list>
          <mat-list-item style="margin-top:10px !important; margin-bottom:10px !important"
            (click)="expandSubmenu(menu, submenu.routes); toggle()" *ngFor="let submenu of menu.subMenu"
            [ngClass]="{'is-active': matchUrl(submenu.routes)}" routerLinkActive="is-active" [routerLinkActiveOptions]="{exact:
                true}">
            <mat-icon>{{submenu.icon}}</mat-icon> <span style="font-size: 13px !important;">
              {{submenu.name}}</span>
          </mat-list-item>
        </mat-nav-list>
      </mat-expansion-panel>
    </ng-template>
  </mat-accordion>
</div>
<div *ngIf="!toggleSideNav" class=before-login>
  <router-outlet></router-outlet>
</div>