import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/shared/abstract/base.component';
import { DataManagerService } from 'src/app/services/DataManager/data-manager.service';
import { AppConstants } from 'src/app/shared/constants/app-constants';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.css'],
})
export class UnsubscribeComponent extends BaseComponent implements OnInit {
  id: any;
  status: boolean;
  constructor(
    private router: Router,
    dataManagerService: DataManagerService,
    private route: ActivatedRoute,
  ) {
    super();
    this.service = dataManagerService;
    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });
    this.checkRecurringPaymentStatus();
  }

  ngOnInit(): void {}

  backtoHome() {
    this.router.navigate(['dashboard']);
  }
  cancelPayment() {
    let url = AppConstants.BASE_URL + '/payments/unsubscribe/';
    this.service.unSubscribePayment(url, this.id).subscribe({
      next: (res: any) => {
        console.log(res);
        if (res['code'] == '1') {
          console.log('here');
          this.router.navigate(['../../unsubscribed'], {
            relativeTo: this.route,
            skipLocationChange: true,
          });
        } else {
          this.status = false;
        }
      },
    });
  }
  checkRecurringPaymentStatus() {
    let url = AppConstants.BASE_URL + '/payments/unsubscribe/check/';
    this.service.unSubscribePayment(url, this.id).subscribe({
      next: (res: any) => {
        this.status = res['status'];
      },
    });
  }
}
