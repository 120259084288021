<div id="expenseRegister" class="mx-0 mx-lg-3 mx-xl-3 my-2 my-lg-5 my-xl-5">
    <div class="card">
        <div class="card-body px-0 px-xl-3 px-lg-3 px-md-3">
            <div class="form-ajax-box">

                <div class="row" *ngIf="!topErrorMessage">
                    <div class="col-md-12 error text-center">
                        {{errorMsg}}
                    </div>
                </div>
                <form class="form-horizontal nsicc-form validated" [formGroup]="eventRegisterForm"
                    (ngSubmit)="onSubmit()">
                    <div class="toolbar-nav">
                        <div class="row">
                            <mat-toolbar class="main-header mb-2">
                                <mat-toolbar-row>
                                    <span>{{ headings }}</span>
                                    <span class="example-spacer"></span>

                                </mat-toolbar-row>
                            </mat-toolbar>
                        </div>
                    </div>
                    <ng-container *ngIf="topErrorMessage">
                        <div class="row err" *ngFor="let obj of keys1 ">
                            <div class="col-md-12 mb-1" *ngFor="let value of obj ">
                                <p class="col-md-12 error text-center" id="err">{{value}}</p>
                            </div>
                        </div>
                    </ng-container>
                    <div class="row" *ngIf="eventDateList.length >=1">
                        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <div class="event-container">
                                <div class="event-info">
                                    <h3>Event Information:</h3>
                                    <div class="row">
                                        <div *ngIf="data?.event_cover_img != null && data?.event_cover_img !== '' "
                                            class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <img class="event-image" src="{{data.event_cover_img}}">
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <h4>Title: <span class="event-title">{{ data?.event_title }}</span></h4>
                                            <h4>Cost: <span class="event-cost">{{ data?.event_cost | currency }} {{
                                                    data?.is_static_cost ==0 ? "Per Day" : "" }}</span>
                                            </h4>
                                            <h4><strong> Description: </strong></h4>
                                            <div class="event-description" [innerHTML]="safeHtml"></div>
                                            <br>
                                            <div *ngIf="data?.discount_id != null && data?.isActive==true">

                                                <h4><strong> Discount Information: </strong></h4>
                                                <h4>Discount Amount: <span class="discount-amount">{{ data?.value }}{{
                                                        data?.type
                                                        == 0
                                                        ?
                                                        "$" : "%" }}</span></h4>
                                                <div *ngIf="data?.hasQtyLimit == 1">
                                                    <h4>Discount Participants Range: <span class="discount-range">{{
                                                            data?.startQty
                                                            }} -
                                                            {{ data?.endQty }}</span></h4>
                                                    <p style="color:grey"> The discount will be applied from the {{
                                                        data?.startQty }}th to
                                                        the {{ data?.endQty }}th

                                                    </p>
                                                </div>
                                                <div *ngIf="data?.hasAmountLimit == 1">
                                                    <h4>Discount Range: <span class="discount-range">{{ data?.minAmount
                                                            |
                                                            currency }}
                                                            -
                                                            {{ data?.maxAmount | currency }}</span></h4>
                                                    <p style="color:grey"> The applicable discount will be deducted from
                                                        the total amount
                                                        in cash, ranging from {{ data?.minAmount | currency }} to {{
                                                        data?.maxAmount | currency }}.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <div class="event-container">
                                <div class="col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12 p-0 mt-2"
                                    *ngIf="!formLoader; else showLoader">
                                    <div class="row">
                                        <div class="form-group col-12 col-lg-6 col-sm-6 col-md-6 c  ol-xl-6">
                                            <label class="form-label" for="full_name">Full Name of Participant <span
                                                    class="asterix"> *
                                                </span></label>
                                            <input type="text" name="full_name" id="full_name"
                                                formControlName="full_name" class="form-control" maxlength="50"
                                                [ngClass]="{ 'is-invalid': submitted && eventRegisterFormControl.full_name.errors }"
                                                (keypress)="letterOnly($event)">
                                            <div *ngIf="submitted && eventRegisterFormControl.full_name.errors"
                                                class="invalid-feedback p-0">
                                                <div *ngIf="eventRegisterFormControl.full_name.errors?.required">Full
                                                    name of
                                                    participant is
                                                    required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6">
                                            <label for="usr" class="form-label">Email <span class="asterix"> *
                                                </span></label>
                                            <input type="email" formControlName="email" class="form-control"
                                                pattern="^\w+([\.-]?\w+)*(\+[a-z0-9-]+)?@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                                                [ngClass]="{ 'is-invalid': submitted && eventRegisterFormControl.email.errors }" />
                                            <div *ngIf="submitted && eventRegisterFormControl.email.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="eventRegisterFormControl.email.errors?.required">Email is
                                                    required.
                                                </div>
                                                <div *ngIf="eventRegisterFormControl.email.errors.pattern">Invaild
                                                    email.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6">
                                            <label for="usr" class="form-label">Contact number <span class="asterix"> *
                                                </span></label>
                                            <div class="row m-0">
                                                <span
                                                    class="input-group-addon numberone last-d col-2 col-lg-2 col-xl-2 col-md-2 col-sm-2 text-center pl-2 pr-2 phone">+1</span>
                                                <input
                                                    class=" phone form-control col-10 col-lg-10 col-xl-10 col-sm-10  col-md-10 "
                                                    id="phone" name="phone" formControlName='phone'
                                                    placeholder="(000) 000-0000" formControlName="contact_number"
                                                    type="tel" appPhoneMask maxlength="14" autocomplete="off"
                                                    [ngClass]="{ 'is-invalid': submitted && eventRegisterFormControl.contact_number.errors }"
                                                    value="" />
                                                <div *ngIf="submitted && eventRegisterFormControl.contact_number.errors"
                                                    class="invalid-feedback p-0">
                                                    <div
                                                        *ngIf="eventRegisterFormControl.contact_number.errors?.required">
                                                        Contact
                                                        number is
                                                        required.
                                                    </div>
                                                    <div
                                                        *ngIf="eventRegisterFormControl.contact_number.errors?.pattern">
                                                        Invalid
                                                        contact
                                                        number.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-6 col-lg-6 col-sm-12 col-xs-12 col-md-6 col-xl-6">
                                            <label class="form-label" for="quantity"> Participants Quantity <span
                                                    class="asterix">
                                                    *
                                                </span></label>
                                            <input type="number" formControlName="quantity" id="quantity"
                                                class="form-control example-full-width" min="1"
                                                max="{{ data.registration_capacity ?? 10}}" value="1" step="1"
                                                [ngClass]="{ 'is-invalid': submitted && eventRegisterFormControl.quantity.errors }"
                                                (keyup)="changeTotal()" (keypress)="numericOnly($event)" />
                                            <div *ngIf="submitted && eventRegisterFormControl.quantity.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="eventRegisterFormControl.quantity?.errors?.required">
                                                    Quantity is required.
                                                </div>
                                            </div>
                                        </div>
                                        <mat-accordion class="example-headers-align" multi
                                            *ngIf="this.eventRegisterForm.value.quantity > 1">
                                            <mat-expansion-panel class="mb-4 mt-2"
                                                [ngClass]="{'invalid-accordion': isAccordionInvalid()}">
                                                <mat-expansion-panel-header>
                                                    <mat-panel-title>Participants</mat-panel-title>
                                                    <mat-panel-description>
                                                        Participants' Info
                                                        <mat-icon>account_circle</mat-icon>
                                                    </mat-panel-description>
                                                </mat-expansion-panel-header>
                                                <div formArrayName="participantNames">
                                                    <div *ngFor="let participant of participantNames.controls; let i = index"
                                                        class="form-row">
                                                        <div class="form-group col-md-4">
                                                            <label class="form-label participant-label"
                                                                for="participantName{{i}}">Participant {{i + 2}} Full
                                                                Name
                                                                <span class="asterisk">*</span>
                                                            </label>
                                                            <input type="text" [formControl]="participant.get('name')"
                                                                id="participantName{{i}}"
                                                                class="form-control participant-input" />
                                                            <div *ngIf="submitted && participant.get('name').errors"
                                                                class="invalid-feedback">
                                                                <div *ngIf="participant.get('name').errors.required"
                                                                    class="invalid-left-space">
                                                                    Participant name is required.
                                                                </div>
                                                                <div *ngIf="participant.get('name').errors.duplicateName"
                                                                    class="invalid-left-space">
                                                                    Duplicate participant name is not allowed.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group col-md-4">
                                                            <label class="form-label participant-label"
                                                                for="participantType{{i}}">Participant {{i + 2}}
                                                                relation
                                                                <span class="asterisk">*</span>
                                                            </label>
                                                            <mat-select [formControl]="participant.get('relation')"
                                                                id="participantType{{i}}"
                                                                class="form-control participant-input">
                                                                <mat-option *ngIf="data.gender==1 || data.gender ==2"
                                                                    value="son">Son</mat-option>
                                                                <mat-option *ngIf="data.gender==1 || data.gender ==2"
                                                                    value="father">Father</mat-option>
                                                                <mat-option *ngIf="data.gender==0 || data.gender ==2"
                                                                    value="mother">Mother</mat-option>
                                                                <mat-option *ngIf="data.gender==0 || data.gender ==2"
                                                                    value="daughter">Daughter
                                                                </mat-option>
                                                                <mat-option
                                                                    *ngIf="data.gender==0 ||data.gender==1 || data.gender ==2"
                                                                    value="wife">Spouse</mat-option>
                                                            </mat-select>
                                                            <div *ngIf="submitted && participant.get('type')?.hasError('required')"
                                                                class="invalid-feedback">
                                                                Participant relation is required.
                                                            </div>
                                                        </div>
                                                        <div class="form-group col-md-4" *ngIf="data.has_gender==1">
                                                            <label class="form-label participant-label"
                                                                for="participantGender{{i}}">Participant {{i + 2}}
                                                                Gender
                                                                <span class="asterisk">*</span>
                                                            </label>
                                                            <mat-select [formControl]="participant.get('gender')"
                                                                id="participantGender{{i}}"
                                                                class="form-control participant-input">
                                                                <mat-option *ngIf="data.gender==1 || data.gender ==2"
                                                                    value="male">
                                                                    {{ data.gender==1?"Male Only":"Male" }}
                                                                </mat-option>
                                                                <mat-option *ngIf="data.gender==0 || data.gender ==2"
                                                                    value="female">

                                                                    {{ data.gender==0?"Female Only":"Female" }}
                                                                </mat-option>
                                                            </mat-select>
                                                            <div *ngIf="submitted && participant.get('gender')?.hasError('required')"
                                                                class="invalid-feedback">
                                                                Participant gender is required.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </mat-expansion-panel>

                                        </mat-accordion>
                                        <div *ngIf="submitted && isAccordionInvalid()"
                                            class="invalid-feedback remove-up-space">

                                            Participants info is required.

                                        </div>



                                        <div class="form-group col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6"
                                            *ngIf="data.has_gender==1">
                                            <label class="form-label" for="Gender"> Participant Gender <span
                                                    class="asterix">*</span></label>
                                            <div
                                                class="form-check-radio col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12">
                                                <label class="form-label" class="form-label"
                                                    *ngIf="data.gender==1 || data.gender ==2"
                                                    class="text-muted form-check-label mr-5" for="gender-0">
                                                    <input type="radio" formControlName="gender" name="gender" value="1"
                                                        class="filled-in form-check-input" id="gender-0"
                                                        [ngClass]="{ 'is-invalid': submitted && eventRegisterFormControl.gender.errors }"
                                                        data-parsley-multiple="gender"> <label class="form-label"
                                                        class="form-label" for="gender-0">
                                                        {{ data.gender == 1 ? "Male only" : "Male" }}

                                                    </label>
                                                </label>
                                                <label class="form-label" *ngIf="data.gender==0 || data.gender ==2"
                                                    class="form-check-label" for="gender-1">
                                                    <input type="radio" formControlName="gender" name="gender" value="0"
                                                        class="filled-in form-check-input" id="gender-1"
                                                        [ngClass]="{ 'is-invalid': submitted && eventRegisterFormControl.gender.errors }"
                                                        data-parsley-multiple="gender"><label class="form-label"
                                                        class="form-label" for="gender-1">
                                                        {{ data.gender == 0 ? "Female only" : "Female" }}
                                                    </label>
                                                </label>
                                            </div>
                                            <div *ngIf="submitted && eventRegisterFormControl.gender.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="eventRegisterFormControl.gender.errors?.required">Gender is
                                                    required.
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="data.min_age_required == 1"
                                            class="form-group col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6">
                                            <label class="form-label" for="age"> Age <span class="asterix">
                                                    *
                                                </span></label>
                                            <input type="tel" formControlName="age" id="age" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && eventRegisterFormControl.age.errors }"
                                                (keypress)="numericOnly($event)" />
                                            <div *ngIf="submitted && eventRegisterFormControl.age?.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="eventRegisterFormControl.age?.errors.required">Age is
                                                    required.
                                                </div>
                                                <div *ngIf="eventRegisterFormControl.age?.errors?.min">Age should be
                                                    minimum
                                                    {{min}}.
                                                </div>
                                                <div *ngIf="eventRegisterFormControl.age?.errors?.max">Age should be
                                                    maximum
                                                    {{max}}.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12">

                                            <mat-form-field class="example-full-width" appearance='outline'>
                                                <mat-label>Any Additional
                                                    Information?</mat-label>
                                                <textarea matInput formControlName="additional_information"
                                                    id="additional_information"
                                                    placeholder="Allergies disclosure or any other information"></textarea>
                                            </mat-form-field>

                                        </div>
                                        <div class="form-group col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6"
                                            *ngIf="event_cost_type !== 1">
                                            <label for="Mode of payment" class="col-12 p-0 mt-2 form-label"> Mode of
                                                payment<span class="asterix">
                                                    *
                                                </span></label>
                                            <div
                                                class="form-check-radio col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12">
                                                <!-- <label class="form-label" class="text-muted form-check-label mr-5" for="Cash">
                                        <input type="radio" formControlName="payment_method" name="payment_method"
                                            value="1" class="filled-in form-check-input" id="payment_method-0"
                                            (change)="changeName('Cash')"
                                            [ngClass]="{ 'is-invalid': submitted && eventRegisterFormControl.payment_method.errors }"
                                            data-parsley-multiple="payment_method"> <label class="form-label"
                                            for="payment_method-0">Cash
                                        </label>
                                    </label> -->
                                                <label class="form-label" class="form-check-label  mr-5" for="Online">
                                                    <input type="radio" formControlName="payment_method"
                                                        name="payment_method" value="4"
                                                        class="filled-in form-check-input" id="payment_method-2"
                                                        (change)="changeName('Online')"
                                                        [ngClass]="{ 'is-invalid': submitted && eventRegisterFormControl.payment_method.errors }"
                                                        data-parsley-multiple="payment_method"><label class="form-label"
                                                        for="payment_method-2">Online</label>
                                                </label>
                                            </div>

                                            <p class="" *ngIf="eventRegisterForm.value.payment_method === 1">Note:
                                                Please
                                                make
                                                sure
                                                to complete the Cash payment to the event admin in person!!.</p>
                                            <div *ngIf="submitted && eventRegisterFormControl.payment_method.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="eventRegisterFormControl.payment_method.errors?.required">
                                                    Mode of payment
                                                    is
                                                    required.
                                                </div>
                                            </div>
                                            <div *ngIf="eventRegisterForm.value.payment_method === 3" class="info">
                                                <p class="">To send donations through EMAIL transfer from your bank
                                                    account,
                                                    please
                                                    use the following information:</p>
                                                <address>
                                                    Name: Kearney Lake Masjid<br>
                                                    Email: <a
                                                        href="mailto:treasurer@nsicc.ca">treasurer&#64;nsicc.ca</a><br>
                                                    Security Question: What is the donation for?<br>
                                                    Security Answer: general<br>
                                                </address>
                                                <p>Enter the amount and purpose you want to donate and send</p>
                                            </div>
                                        </div>
                                        <div class="col-12 mb-2 mt-2">
                                            <h4 class="event_title">Total: <span><strong> {{
                                                        totalPrice * (
                                                        data?.is_static_cost ==0 ?
                                                        eventRegisterForm.value.event_id.length : 1 )
                                                        |
                                                        currency }}
                                                    </strong></span></h4>
                                        </div>
                                        <div class="form-group form-check consent "
                                            [ngClass]="{'col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6': event_cost_type === 1 }">

                                            <label for="agree_to" class=" mb-4 mt-4 form-check-label pr-3">
                                                <input type="checkbox" formControlName="agree_to" id="agree_to"
                                                    value="1" class="form-check-input" />
                                                Consent: I give my permission to NSICC to use photos that include my
                                                child
                                                in
                                                the
                                                proper social media streams and for the masjid activities, like for
                                                advertising
                                                purposes.</label>
                                        </div>
                                        <div class="form-group col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12 mt-2">
                                            <label class="form-label" for="Share with"> Select event dates you want to
                                                register
                                                for
                                                <span class="asterix"> *
                                                </span></label>
                                            <ul id="ul_top_hypers">
                                                <div class="row">
                                                    <div class="col-8 col-xl-8 col-md-8 col-sm-8 col-xs-8">
                                                        <li *ngFor="let dates of eventDateList; let i=index">
                                                            <label class="form-label form-check-label mr-3"
                                                                for="{{dates.event_date}}">
                                                                <input type="checkbox" [value]="dates.id"
                                                                    id="{{dates.event_date}}" [checked]="dates.checked"
                                                                    (change)="onCheckboxChange($event, dates.event_date)"
                                                                    [ngClass]="{ 'is-invalid': submitted && eventRegisterFormControl.event_id.errors }" />
                                                                {{dates.event_date }}
                                                            </label>
                                                        </li>
                                                    </div>
                                                </div>
                                            </ul>
                                            <p class="error"
                                                *ngIf="submitted && this.eventRegisterForm.controls['event_id'].errors?.['required']">
                                                Checkbox is required, select at least one value.
                                            </p>
                                        </div>
                                    </div>

                                    <div class="row" style="align-items:baseline;">
                                        <div class="col-1 col-lg-1 col-sm-1 col-md-1 col-xl-1 d-flex mt-2">
                                            <div class="btn-group mr-2">
                                                <button class="btn btn-primary" [disabled]="loadFlag2"
                                                    name="save">{{buttonText}} <i *ngIf="loadFlag2"
                                                        class="fa fa-spinner fa-pulse loader mt-1 ml-1"></i></button>
                                            </div>
                                            <div class="btn-group">
                                                <button class="btn btn-secondary" (click)="cancel()">Cancel</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="card-body px-0 px-xl-3 px-lg-3 px-md-3" *ngIf="!activeDate">
                        <div class="message-container">
                            <div class="message">
                                <h2>Event Full Capacity</h2>
                                <p>We regret to inform you that all event dates have reached full capacity.
                                </p>
                                <p>Thank you
                                    for your interest.</p>
                                <p>Please feel free to get in touch with us at {{ data.email }} for any inquiries or
                                    assistance
                                    you may require.</p>
                            </div>
                        </div>

                    </div>
                    <ng-template #showLoader>
                        <div class="text-center loader">
                            <mat-progress-spinner diameter=50 style="margin: 0 auto" mode="indeterminate"
                                color="accent">
                            </mat-progress-spinner>
                        </div>
                    </ng-template>

                </form>
            </div>
        </div>
    </div>
</div>