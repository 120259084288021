import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { DataManagerService } from '../services/DataManager/data-manager.service';
import { BaseComponent } from '../shared/abstract/base.component';
import { AppConstants } from '../shared/constants/app-constants';

@Component({
  selector: 'app-add-student-class',
  templateUrl: './add-student-class.component.html',
  styleUrls: ['./add-student-class.component.css'],
})
export class AddStudentClassComponent extends BaseComponent implements OnInit {
  public schoolClassForm: FormGroup;
  public loged_user = JSON.parse(localStorage.getItem('user') || 'null');
  schoolClassUrl: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    dataManagerService: DataManagerService,
    private datePipe: DatePipe,
    public dialogRef: MatDialogRef<AddStudentClassComponent>,
  ) {
    super();
    this.service = dataManagerService;
    this.schoolClassUrl = AppConstants.BASE_URL + '/masterstudentclass';
    if (dialogData) {
      this.headings = 'Update School Class';
      this.buttonText = 'Update';
    } else {
      this.headings = 'Create School Class';
      this.buttonText = 'Save';
    }
  }
  ngOnInit(): void {
    this.setSchoolClassForm();
  }
  get schoolClassFormControl() {
    return this.schoolClassForm.controls;
  }
  setSchoolClassForm() {
    this.schoolClassForm = this.formBuilder.group({
      class_name: [
        this.dialogData && this.dialogData.data.class_name
          ? this.dialogData.data.class_name
          : '',
        [Validators.required],
      ],
      school_type: [
        this.dialogData && this.dialogData.data.school_type
          ? this.dialogData.data.school_type == 'Al-Furqan Program'
            ? '2'
            : '1'
          : '',
        [Validators.required],
      ],
    });
  }

  onSubmit() {
    if (this.dialogRef.getState() == 0) {
      this.submitted = true;
      if (this.schoolClassForm.invalid) {
        return;
      } else {
        this.loadFlag2 = true;
        if (this.dialogData) {
          this.schoolClassForm.value.id = this.dialogData.data.student_class_id;
        }
        this.service
          .create(this.schoolClassUrl, this.schoolClassForm.value)
          .subscribe({
            next: (res: any) => {
              if (res['status'] == 'success') {
                this.loadFlag2 = false;
                this.dialogRef.close(true);
                this.service.showSnackBar(res.message, 'ok', 3000);
              } else {
                this.loadFlag2 = false;
                this.topErrorMessage = res.message;
                this.keys = Object.keys(this.topErrorMessage);
                this.keys1 = Object.values(this.topErrorMessage);
                let i;
                let j;
                const name = ['class_name', 'school_type'];
                for (i = 0; i < this.keys.length; i++) {
                  for (j = 0; j < name.length; j++) {
                    if (this.keys[i].match(name[j])) {
                      const documents = document.getElementById(name[j]);
                      if (documents) {
                        documents.style.borderColor = '#a94442';
                        documents.style.display = 'block';
                      }
                    }
                  }
                }
              }
            },
            error: (error) => {
              this.loadFlag2 = false;
              this.service.showSnackBar(error.message, 'ok', 3000);
            },
          });
      }
    } else {
      this.loadFlag2 = false;
      this.dialogRef.close(true);
    }
  }
  cancel() {
    this.dialogRef.close(true);
  }
}
