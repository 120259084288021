import { Component, DoCheck, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/shared/abstract/base.component';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css'],
})
export class EventComponent extends BaseComponent implements OnInit, DoCheck {
  public header: string;
  create: boolean;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit(): void {}
  ngDoCheck() {
    if (location.pathname === '/all-events') {
      this.router.navigate(['/all-events/events']);
    }
    if (this.create_permission) {
      this.create = this.create_permission;
    }
  }
  heading(): any {
    if (
      this.matchUrl('/all-events/events') ||
      this.matchUrl('/add-event') ||
      this.matchUrl('/edit-event') ||
      this.matchUrl('/view-event') ||
      this.matchUrl('/register-event') ||
      this.matchUrl('all-events/upcomingevent') ||
      this.matchUrl('/checkout') ||
      this.matchUrl('/view-myevent') ||
      this.matchUrl('/myevents')
    ) {
      return 'Events';
    }
  }
  createNew() {
    console.log('correct ' , this.route)
    this.router.navigate(['add-event'], { relativeTo: this.route });
  }
}
