import { Component, DoCheck, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AddPurposeOfDonationDialogComponent } from 'src/app/dialogs/add-purpose-of-donation-dialog/add-purpose-of-donation-dialog.component';
import { BaseComponent } from 'src/app/shared/abstract/base.component';

@Component({
  selector: 'app-donations',
  templateUrl: './donations.component.html',
  styleUrls: ['./donations.component.css'],
})
export class DonationsComponent
  extends BaseComponent
  implements OnInit, DoCheck
{
  public header: string;
  create: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {
    super();
  }

  ngOnInit(): void {}
  ngDoCheck() {
    if (location.pathname === '/donations') {
      this.router.navigate(['/donations/alldonation']);
    }
    if (this.create_permission) {
      this.create = this.create_permission;
    }
  }
  createNew() {
    if (this.matchUrl('/donations/alldonation')) {
      this.router.navigate(['add-donation'], { relativeTo: this.route });
    } else {
      const dialogRef = this.dialog.open(AddPurposeOfDonationDialogComponent, {
        disableClose: true,
        width: '500px',
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.router
            .navigateByUrl('home', { skipLocationChange: true })
            .then(() => {
              this.router.navigate([location.pathname]);
            });
        }
      });
    }
  }
  heading(): any {
    if (
      this.matchUrl('/donations/alldonation') ||
      this.matchUrl('/donations/add-donation') ||
      this.matchUrl('/donations/edit-donation') ||
      this.matchUrl('/donations/view-donation') ||
      this.matchUrl('/donations/checkout')
    ) {
      return 'Donation';
    } else {
      return 'Purpose Of Donation';
    }
  }
  btnNameupdate() {
    if (this.matchUrl('/donations/alldonation')) {
      return 'New Donation';
    } else {
      return 'Add';
    }
  }
}
