<div class="courseRegisterView"
    [ngClass]="{'courseRegisterView': ( data.data.course_type == '2'&& studentsControl.length > 0) || RegisterCourseForm.value.payment_frequency == '4'    , 'courseFree': studentsControl.length > 0 && data.data.course_type == '1' }">
    <div class="toolbar-nav">
        <div class="row">
            <div class="col">
                <h2 class="title pl-12">{{headings}}</h2>
            </div>
            <div class="toolbar-nav">
                <ng-container *ngIf="topErrorMessage">
                    <div class="row err" *ngFor="let obj of keys1 ">
                        <div class="col-md-12 mb-1" *ngFor="let value of obj ">
                            <p class="col-md-12 error text-center" id="err">{{value}}</p>
                        </div>
                    </div>
                </ng-container>
                <div class="row" *ngIf="!topErrorMessage">
                    <div class="col-md-12 error">
                        {{errorMsg}}
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-body p-0" id="nsicc-modal-content">
            <div class="form-ajax-box">
                <form class="form-horizontal nsicc-form validated" [formGroup]="RegisterCourseForm">
                    <div class="col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12 p-0">
                        <div class="row">
                            <p style="font-weight:bold ; color:red ">**Monthly payment option is available.</p>
                        </div>

                        <div class="row align-items-center">
                            <div class="form-group col-12 col-lg-6 col-sm-12 col-md-6 col-xl-6"
                                *ngIf="data.data.course_type == '2'">
                                <label class="form-label question_label" for="course_fee"> Total Course Fee (in CAD)
                                    <span class="asterix"> *
                                    </span></label>
                                <input type="text" name="course_fee" formControlName="course_fee" id="course_fee"
                                    [(ngModel)]="course_fee" value="" required="" [readonly]="data.data.course_fee"
                                    class="form-control" (keypress)="numericOnly($event)">
                            </div>
                            <div class="form-group col-12 col-lg-6 col-sm-12 col-md-12 col-xl-6"
                                [ngClass]="{'col-xl-12': data.data.course_type == '1'}">
                                <label class="form-label question_label" for="no_of_child"> How many
                                    students you want to
                                    register? <span class="asterix"> *
                                    </span></label>
                                <select class="form-select" aria-label="Default select example"
                                    formControlName="no_of_child" id="no_of_child" (change)="onChangeStudents($event)"
                                    [ngClass]="{ 'is-invalid': submitted && RegisterCourseFormControl.no_of_child.errors }">
                                    <option value="" disabled selected>Select</option>
                                    <option *ngFor="let i of [1,2,3,4,5]" value="{{i}}">{{i}}</option>
                                </select>
                                <div *ngIf="submitted && RegisterCourseFormControl.no_of_child.errors"
                                    class="invalid-feedback p-0">
                                    <div *ngIf="RegisterCourseFormControl.no_of_child.errors.required">No of student is
                                        required.
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div *ngFor="let student of studentsControl.controls; let i = index"
                            class="list-group list-group-flush">
                            <div class="list-group-item col-12 px-0">
                                <mat-card appearance="outlined" class="mx-3 px-0">
                                    <h2 class="title accordion mb-0" (click)="expand(i)"
                                        [ngClass]="{'show-feedback': submitted && RegisterCourseForm.controls.students.controls[i].status === 'INVALID'}">
                                        Student Details {{i +
                                        1}} : <i class="fa"
                                            [ngClass]="{'fa-angle-up': currentIndex == i, 'fa-angle-down': currentIndex != i }"
                                            style="font-size:24px;cursor: pointer; float: right;"></i> </h2>

                                    <div class="col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12 p-0"
                                        [ngClass]="{'mt-3': currentIndex === i}">
                                        <div [formGroup]="student" class="row"
                                            *ngIf="(currentIndex == 0 && i == 0) || (currentIndex === i && i > 0) ">
                                            <div class="form-group col-12 col-lg-6 col-sm-12 col-md-6 col-xl-6">
                                                <label class="form-label" class="form-label" for="name"> Student
                                                    full
                                                    name <span class="asterix">
                                                        *
                                                    </span></label>
                                                <input type="text" name="name" formControlName="name" maxlength="50"
                                                    id="name" value="" required="" class="form-control"
                                                    (keypress)="letterOnly($event)"
                                                    [ngClass]="{ 'is-invalid': submitted && student.controls.name.errors }">
                                                <div *ngIf="submitted && student.controls.name.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="student.controls.name.errors.required">Student full
                                                        name
                                                        is
                                                        required.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group col-12 col-lg-6 col-sm-12 col-md-6 col-xl-6">
                                                <label class="form-label" class="form-label" for="Gender"> Gender
                                                    <span class="asterix">
                                                        *
                                                    </span></label>
                                                <div class="form-check col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12">
                                                    <label class="form-label" class="form-label" class="form-label"
                                                        class="text-muted form-check-label mr-5" for="gender-0">
                                                        <input type="radio" formControlName="gender" name="gender"
                                                            value="1" class="filled-in form-check-input" id="gender-0"
                                                            [ngClass]="{ 'is-invalid': submitted && student.controls.gender.errors }"
                                                            data-parsley-multiple="gender"> <label class="form-label"
                                                            class="form-label" class="form-label" for="gender-0">Male
                                                        </label>
                                                    </label>
                                                    <label class="form-label" class="form-label"
                                                        class="form-check-label" for="gender-1">
                                                        <input type="radio" formControlName="gender" name="gender"
                                                            value="2" class="filled-in form-check-input" id="gender-1"
                                                            [ngClass]="{ 'is-invalid': submitted && student.controls.gender.errors }"
                                                            data-parsley-multiple="gender"><label class="form-label"
                                                            class="form-label" class="form-label"
                                                            for="gender-1">Female</label>
                                                    </label>
                                                </div>

                                                <div *ngIf="submitted && student.controls.gender.errors"
                                                    class="invalid-feedback p-0">
                                                    <div *ngIf="student.controls.gender.errors.required">Gender is
                                                        required.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group col-12 col-lg-6 col-sm-12 col-md-6 col-xl-6">
                                                <label class="form-label" class="form-label" for="dob"> Birth date <span
                                                        class="asterix"> *
                                                    </span></label>
                                                <mat-form-field class="full-width" appearance="outline">
                                                    <input matInput [matDatepicker]="picker" (click)="picker.open()"
                                                        [min]="minDate" [max]="maxDate" formControlName="dob" required
                                                        id="dob">
                                                    <mat-datepicker-toggle matSuffix [for]="picker">
                                                    </mat-datepicker-toggle>
                                                    <mat-datepicker #picker></mat-datepicker>
                                                </mat-form-field>
                                                <mat-error *ngIf="submitted && student.controls.dob.errors"
                                                    class="invalid-feedback">
                                                    <mat-error class="error-message"
                                                        *ngIf="student.controls.dob.errors.required">
                                                        Birth
                                                        date is
                                                        required.</mat-error>
                                                </mat-error>
                                            </div>
                                            <div class="form-group col-12 col-lg-6 col-sm-12 col-md-6 col-xl-6">
                                                <label for="email" class="form-label">Email <span class="asterix"> *
                                                    </span></label>
                                                <input type="email" formControlName="email" id="email"
                                                    class="form-control"
                                                    pattern="^\w+([\.-]?\w+)*(\+[a-z0-9-]+)?@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                                                    [ngClass]="{ 'is-invalid': submitted && student.controls.email.errors }" />
                                                <div *ngIf="submitted && student.controls.email.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="student.controls.email.errors.required">Email is
                                                        required.
                                                    </div>
                                                    <div *ngIf="student.controls.email.errors.pattern">Invaild email.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group col-12 col-lg-6 col-sm-12 col-md-6 col-xl-6">
                                                <label for="phone" class="form-label">Contact Number<span
                                                        class="asterix"> *
                                                    </span></label>
                                                <div class="row m-0">
                                                    <span
                                                        class="input-group-addon numberone last-d col-2 col-lg-2 col-xl-2 col-md-3 col-sm-2 text-center pl-2 pr-2 pt-2">+1</span>
                                                    <input
                                                        class="phone form-full-width form-control col-10 col-lg-10 col-xl-10 col-sm-10  col-md-9 "
                                                        id="phone" name="phone" formControlName='phone'
                                                        placeholder="(000) 000-0000" formControlName="contact_number"
                                                        type="tel" appPhoneMask maxlength="14" autocomplete="off"
                                                        [ngClass]="{ 'is-invalid': submitted && student.controls.contact_number.errors }"
                                                        value="" />
                                                    <div *ngIf="submitted && student.controls.contact_number.errors"
                                                        class="invalid-feedback p-0">
                                                        <div *ngIf="student.controls.contact_number.errors.required">
                                                            Contact number is
                                                            required.
                                                        </div>
                                                        <div *ngIf="student.controls.contact_number.errors.pattern">
                                                            Invalid contact
                                                            number.</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group col-12 col-lg-6 col-sm-12 col-md-6 col-xl-6">
                                                <label class="form-label" class="form-label" for="pickup_person_name">
                                                    Authorized pickup
                                                    person
                                                    name </label>
                                                <input type="text" name="pickup_person_name" id="pickup_person_name"
                                                    formControlName="pickup_person_name" maxlength="250" value=""
                                                    class="form-control">
                                            </div>
                                            <div class="form-group col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12">
                                                <label class="form-label" class="form-label" for="additional_info">
                                                    Additional
                                                    information, if any:
                                                </label>
                                                <input type="text" name="additional_info" maxlength="250"
                                                    id="additional_info" value="" formControlName="additional_info"
                                                    class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                </mat-card>
                            </div>

                        </div>
                        <div class="row" *ngIf="data.data.course_type == '2'">
                            <div class="form-group col-9 col-lg-5 col-sm-8 col-md-4 col-xl-5">
                                <label class="form-label question_label" for="Discount"> Discount </label>
                                <input type="text" name="discount_code" formControlName="discount_code"
                                    id="discount_code" value="" class="form-control">
                                <span *ngIf="showresponse" class="status">Discount Applied.</span>

                            </div>
                            <div
                                class="form-group col-2 col-lg-1 col-sm-4 col-md-2 col-xl-1 mt-4 pt-1 pl-0 text-sm-end text-left">
                                <button class="btn btn-primary px-2" (click)="verifyDiscount()">Apply</button>
                            </div>
                            <div class="form-group col-12 col-lg-6 col-sm-12 col-md-6 col-xl-6">
                                <label class="form-label question_label" for="payment_amount"> Total Course Fee
                                    (in CAD) <span class="asterix"> *
                                    </span></label>
                                <input type="text" name="payment_amount" formControlName="payment_amount"
                                    [(ngModel)]="courseFeewithDiscount" id="payment_amount" value="" required=""
                                    [readonly]="courseFeewithDiscount" class="form-control"
                                    (keypress)="numericOnly($event)">
                            </div>
                            <div class="form-group col-12 col-lg-6 col-sm-12 col-md-6 col-xl-6">
                                <label class="form-label question_label" for="parent_name"> Parent Full Name
                                    <span class="asterix"> *
                                    </span></label>
                                <input type="text" name="parent_name" formControlName="parent_name" id="parent_name"
                                    value="" required class="form-control">
                            </div>
                            <div class="form-group col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12"
                                *ngIf="data.data.course_type == '2'">
                                <label for="Mode of payment" class="col-12 p-0  form-label"> Mode of payment<span
                                        class="asterix">
                                        *
                                    </span></label>
                                <div class="form-check col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12">
                                    <label class="form-label" class="form-check-label mr-5" for="payment_method-3">
                                        <input type="radio" formControlName="payment_method" name="payment_method"
                                            (change)="changeName('E-Transfer')" value="3"
                                            class="filled-in form-check-input" id="payment_method-3"
                                            [ngClass]="{ 'is-invalid': submitted && RegisterCourseFormControl.payment_method.errors }"
                                            data-parsley-multiple="payment_method"><label class="form-label"
                                            for="payment_method-3">E-Transfer</label>
                                    </label>
                                    <label class="form-label" class="form-check-label  mr-5" for="Online">
                                        <input type="radio" formControlName="payment_method" name="payment_method"
                                            value="4" class="filled-in form-check-input" id="payment_method-2"
                                            (change)="changeName('Online')"
                                            [ngClass]="{ 'is-invalid': submitted && RegisterCourseFormControl.payment_method.errors }"
                                            data-parsley-multiple="payment_method"><label class="form-label"
                                            for="payment_method-2">Online</label>
                                    </label>
                                </div>
                                <div *ngIf="submitted && RegisterCourseFormControl.payment_method.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="RegisterCourseFormControl.payment_method.errors.required">
                                        Mode of payment
                                        is
                                        required.
                                    </div>
                                </div>
                                <div *ngIf="RegisterCourseForm.value.payment_method == 3" class="info">
                                    <p class="">To send donations through EMAIL transfer from your bank account, please
                                        use the following information:</p>
                                    <address>
                                        Name: Kearney Lake Masjid<br>
                                        Email: <a href="mailto:treasurer@nsicc.ca">treasurer&#64;nsicc.ca</a><br>
                                        Security Question: What is the donation for?<br>
                                        Security Answer: general<br>
                                    </address>
                                    <p>Enter the amount and purpose you want to donate and send</p>
                                </div>
                            </div>
                            <div class="row" *ngIf="RegisterCourseForm.value.payment_method == 4">
                                <lable class="form-label">Payment Frequency <span class="asterix">
                                        *
                                    </span></lable>
                                <div class="form-check col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12">
                                    <label class="form-check-label ml-2 mr-5" for="payment_frequency-1">
                                        <input type="radio" formControlName="payment_frequency" name="payment_frequency"
                                            value="1" class="filled-in form-check-input" id="payment_frequency-1"
                                            [ngClass]="{ 'is-invalid': submitted && RegisterCourseFormControl.payment_frequency.errors }"
                                            data-parsley-multiple="payment_frequency_method"><label class="form-label"
                                            for="payment_frequency-1">One Time </label>
                                    </label> <label class="form-check-label  mr-5" for="payment_frequency-4">
                                        <input type="radio" formControlName="payment_frequency" name="payment_frequency"
                                            value="4" class="filled-in form-check-input" id="payment_frequency-4"
                                            [ngClass]="{ 'is-invalid': submitted && RegisterCourseFormControl.payment_frequency.errors }"
                                            data-parsley-multiple="payment_frequency_method"><label class="form-label"
                                            for="payment_frequency-4">Monthly </label>
                                    </label>
                                </div>


                            </div>
                            <div class="row number-of-months" *ngIf="RegisterCourseForm.value.payment_frequency == 4">
                                <label class="form-label"> Number of Months: {{numberOfMonths }}</label>

                            </div>
                            <div class="loading-overlay" *ngIf="isLoading">
                                <div class="loading-spinner"></div>
                                <div class="loading-text">Loading...</div>
                            </div>
                            <ng-container
                                *ngIf="RegisterCourseForm.value.payment_method == 4 &&  RegisterCourseForm.value.payment_frequency != 1  && RegisterCourseForm.value.payment_frequency !== ''">

                                <app-custom-checkout (sendToken)="onSubmit($event)" [amount]="totalPerMonth"
                                    [duration]="duration" [payWord]="payWord" [message]="formMessage">
                                </app-custom-checkout>


                            </ng-container>
                        </div>
                        <div class="row" style="align-items:baseline;">
                            <div class="col-12 col-lg-12 col-sm-12 col-md-12 col-xl-12 d-flex my-3">
                                <div class="btn-group mr-2"
                                    *ngIf="RegisterCourseForm.value.payment_frequency == 1 ||RegisterCourseForm.value.payment_frequency === '' ">
                                    <button class="btn btn-primary" [disabled]="loadFlag2" name="save"
                                        (click)="onSubmit()">{{buttonText}} <i *ngIf="loadFlag2"
                                            class="fa fa-spinner fa-pulse loader mt-1 ml-1"></i></button>
                                </div>
                                <div class="btn-group">
                                    <button class="btn btn-secondary" (click)="cancel()">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>