import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DataManagerService } from 'src/app/services/DataManager/data-manager.service';
import { BaseComponent } from 'src/app/shared/abstract/base.component';
import { AppConstants } from 'src/app/shared/constants/app-constants';

@Component({
  selector: 'app-funeral-payment-dialog',
  templateUrl: './funeral-payment-dialog.component.html',
  styleUrls: ['./funeral-payment-dialog.component.css'],
})
export class FuneralPaymentDialogComponent
  extends BaseComponent
  implements OnInit
{
  public funeralPaymentForm: FormGroup;
  public loged_user = JSON.parse(localStorage.getItem('user') || 'null');
  userLevelList: any;
  funeralSettingsUrl: string = AppConstants.BASE_URL + '/funeral-settings';
  amount: number;
  showtoAdmin: boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    dataManagerService: DataManagerService,
    private datePipe: DatePipe,
    public dialogRef: MatDialogRef<FuneralPaymentDialogComponent>,
  ) {
    super();
    this.service = dataManagerService;
    if (dialogData) {
      this.getUserLevel();
      this.headings = this.dialogData.Header;
      this.buttonText = 'Proceed';
    }
    this.getPaymentAmount();
  }
  ngOnInit(): void {
    this.setFuneralPaymentForm();
  }
  get funeralPaymentFormControl() {
    return this.funeralPaymentForm.controls;
  }
  setFuneralPaymentForm() {
    this.funeralPaymentForm = this.formBuilder.group({
      payment_method: ['', [Validators.required]],
      payment_amount: [''],
      cheque_number: [''],
    });
  }

  onSubmit() {
    if (this.dialogRef.getState() == 0) {
      this.submitted = true;
      if (this.funeralPaymentForm.invalid) {
        return;
      } else {
        this.loadFlag2 = true;
        this.service
          .create(this.dialogData.url, this.funeralPaymentForm.value)
          .subscribe({
            next: (res: any) => {
              if (res['status'] == 'success' && res.data) {
                this.loadFlag2 = false;
                this.dialogRef.close(res.data);
              } else if (res['status'] == 'success' && !res.data) {
                this.loadFlag2 = false;
                this.service.showSnackBar(res.message, 'ok', 3000);
                this.dialogRef.close(false);
              } else {
                this.loadFlag2 = false;
                this.topErrorMessage = res.message;
                this.keys = Object.keys(this.topErrorMessage);
                this.keys1 = Object.values(this.topErrorMessage);
                let i;
                let j;
                const name = [
                  'start_year',
                  'start_month',
                  'payment_method',
                  'cheque_number',
                ];
                for (i = 0; i < this.keys.length; i++) {
                  for (j = 0; j < name.length; j++) {
                    if (this.keys[i].match(name[j])) {
                      const documents = document.getElementById(name[j]);
                      if (documents) {
                        documents.style.borderColor = '#a94442';
                        documents.style.display = 'block';
                      }
                    }
                  }
                }
              }
            },
            error: (error) => {
              this.loadFlag2 = false;
              this.service.showSnackBar(error.message, 'ok', 3000);
            },
          });
      }
    } else {
      this.dialogRef.close();
    }
  }
  cancel() {
    this.dialogRef.close();
  }
  enableChequeNumber(event: any) {
    if (event === 'Cheque') {
      this.buttonText = 'Proceed';
      this.funeralPaymentForm.controls['cheque_number'].setValidators([
        Validators.required,
      ]);
      this.funeralPaymentForm.controls[
        'cheque_number'
      ].updateValueAndValidity();
    } else {
      if (event === 'Online') {
        this.buttonText = 'Proceed To Checkout';
      } else {
        this.buttonText = 'Proceed';
      }
      this.funeralPaymentForm.controls['cheque_number'].clearValidators();
      this.funeralPaymentForm.controls[
        'cheque_number'
      ].updateValueAndValidity();
    }
  }
  getUserLevel() {
    this.formLoader = true;
    const levelUrl = AppConstants.BASE_URL + '/groups/' + this.loged_user.gid;
    this.service.getById(levelUrl).subscribe({
      next: (res: any) => {
        if (res['status'] == 'success') {
          this.userLevelList = res.data.row;
          if (
            this.userLevelList.level == 1 ||
            this.userLevelList.level == 2 ||
            this.userLevelList.level == 4
          ) {
            this.showtoAdmin = true;
          } else {
            this.showtoAdmin = false;
          }
          console.log(this.userLevelList, this.paymentMethodList);
          this.formLoader = false;
        } else {
          this.service.showSnackBar(res.message, 'ok', 3000);
          this.formLoader = false;
        }
      },
      error: (error) => {
        this.formLoader = false;
        this.service.showSnackBar(error.message, 'ok', 3000);
      },
    });
  }
  getPaymentAmount() {
    this.service.getById(this.funeralSettingsUrl).subscribe({
      next: (res: any) => {
        if (res['status'] == 'success') {
          if (res.data) {
            this.formLoader = false;
            this.data = res?.data;
            this.amount = this.data.funeral_amount;

            this.buttonText = 'Save';
          } else {
            this.formLoader = false;
            this.service.showSnackBar(res.message, 'ok', 3000);
          }
        }
      },
      error: (error) => {
        this.formLoader = false;
        this.service.showSnackBar(error.message, 'ok', 3000);
      },
    });
  }
}
