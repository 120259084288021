import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
declare var customcheckout: any;

@Component({
  selector: 'app-custom-checkout',
  templateUrl: './custom-checkout.component.html',
  styleUrls: ['./custom-checkout.component.css'],
})
export class CustomCheckoutComponent implements OnInit {
  isCardNumberComplete = false;
  isCVVComplete = false;
  isExpiryComplete = false;
  payButtonClass: string = 'btn btn-primary';
  processingScreenVisible: boolean = false;
  errorMessages: { [key: string]: string } = {}; // Store error messages
  errorClasses: { [key: string]: string } = {}; // Store error classes
  customCheckout: any;
  cardNumberBackground: any;
  @Input() message: string;
  @Input() amount: number = 0;
  @Input() payWord: string;
  @Input() duration: string;
  @Output() sendToken = new EventEmitter();
  ngAfterViewInit(): void {
    const script = document.createElement('script');
    script.src =
      'https://libs.na.bambora.com/customcheckout/1/customcheckout.js';
    script.onload = () => this.initializeCheckout();
    document.body.appendChild(script);
  }

  initializeCheckout(): void {
    const style = {
      error: {
        color: 'red',
        ':focus': {
          fontStyle: 'italic',
        },
      },
    };

    const classes = {
      error: 'my-error-class',
    };

    const options = {
      placeholder: 'Card number',
      style: style,
      classes: classes,
      brands: ['visa', 'mastercard', 'discover', 'amex'],
    };
    const cvvOptions = {
      placeholder: 'CVV',
      style: style,
      classes: classes,
    };
    const expiryOptions = {
      placeholder: 'Expire Date',
      style: style,
      classes: classes,
    };
    this.customCheckout = customcheckout();
    const cardNumber = this.customCheckout.create('card-number', options);
    cardNumber.mount('#card-number');
    this.customCheckout.create('cvv', cvvOptions).mount('#card-cvv');
    this.customCheckout.create('expiry', expiryOptions).mount('#card-expiry');
    this.customCheckout.on('brand', (event: any) => {
      this.cardNumberBackground =
        event.brand && event.brand !== 'unknown'
          ? `url(https://cdn.na.bambora.com/downloads/images/cards/${event.brand}.svg)`
          : 'none';
    });
    this.customCheckout.on('empty', (event: any) => {
      if (event.empty) {
        if (event.field === 'card-number') {
          this.isCardNumberComplete = false;
        } else if (event.field === 'cvv') {
          this.isCVVComplete = false;
        } else if (event.field === 'expiry') {
          this.isExpiryComplete = false;
        }
        this.setPayButton(false);
      }
    });

    this.customCheckout.on('error', (event: any) => {
      if (event.field === 'card-number') {
        this.showErrorForId('card-number', event.message);
        console.log('Card number has errors: ' + JSON.stringify(event));
      } else if (event.field === 'cvv') {
        this.showErrorForId('cvv', event.message);

        console.log('CVV has errors: ' + JSON.stringify(event));
      } else if (event.field === 'expiry') {
        this.showErrorForId('expiry', event.message);

        console.log('Expiry has errors: ' + JSON.stringify(event));
      }
      this.setPayButton(false);
    });

    this.customCheckout.on('complete', (event: any) => {
      if (event.field === 'card-number') {
        this.isCardNumberComplete = true;
        this.hideErrorForId('card-number');
      } else if (event.field === 'cvv') {
        this.isCVVComplete = true;
        this.hideErrorForId('card-cvv');
      } else if (event.field === 'expiry') {
        this.isExpiryComplete = true;
        this.hideErrorForId('card-expiry');
      }
      if (
        this.isCardNumberComplete &&
        this.isExpiryComplete &&
        this.isExpiryComplete
      ) {
        this.errorMessages = {};
        this.setPayButton(true);
      }
    });
  }
  hideErrorForId(id: string): void {
    this.errorMessages[id] = ''; // Clear error message
    this.errorClasses[id] = '';
  }

  onSubmit(event: Event): void {
    console.log('test');
    event.preventDefault();
    this.setPayButton(false);
    this.toggleProcessingScreen();
    const callback = (result: any) => {
      if (result.error) {
        this.processTokenError(result.error);
      } else {
        this.processTokenSuccess(result.token);
      }
    };
    this.customCheckout.createToken(callback);
  }
  processTokenError(error: any): void {
    const formattedError = JSON.stringify(error, undefined, 2);
    console.log('processTokenError: ' + formattedError);

    this.showErrorFeedback(error.message);
    this.setPayButton(true);
    this.toggleProcessingScreen();
  }
  showErrorForId(id: string, message: string): void {
    this.errorMessages[id] = message; // Set error message
    this.errorClasses[id] = 'form-group has-feedback has-error';
  }
  processTokenSuccess(token: string): void {
    console.log('processTokenSuccess: ' + token);
    // this.showSuccessFeedback('Success! Created token: ' + token);
    this.sendToken.emit(token);
    this.setPayButton(true);
    this.toggleProcessingScreen();
  }
  showSuccessFeedback(message: string): void {
    const checkMark = '\u2714';
    const feedback = document.getElementById('feedback');
    if (feedback) {
      feedback.innerHTML = checkMark + ' ' + message;
      feedback.classList.add('success');
    }
  }
  showErrorFeedback(message: string): void {
    const xMark = '\u2718';
    const feedback = document.getElementById('feedback');
    if (feedback) {
      feedback.innerHTML = xMark + ' ' + message;
      feedback.classList.add('error');
    }
  }

  setPayButton(enabled: boolean): void {
    this.payButtonClass = enabled
      ? 'btn btn-primary'
      : 'btn btn-primary disabled';
  }

  // ...

  toggleProcessingScreen(): void {
    this.processingScreenVisible = !this.processingScreenVisible;
  }
  ngOnInit(): void {}
}
