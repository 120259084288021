import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MessageDialogComponent } from '../dialogs/message-dialog/message-dialog.component';
import { DataManagerService } from '../services/DataManager/data-manager.service';
import { BaseListComponent } from '../shared/abstract/base-list.component';
import { AppConstants } from '../shared/constants/app-constants';
import { HeaderConstants } from '../shared/constants/header-constants';
import { PermissionConstants } from '../shared/constants/permission-constants';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-fundraiser-list',
  templateUrl: './fundraiser-list.component.html',
  styleUrls: ['./fundraiser-list.component.css'],
})
export class FundraiserListComponent
  extends BaseListComponent
  implements OnInit
{
  public loged_user = JSON.parse(localStorage.getItem('user') || 'null');
  deleteUrl: string;
  constructor(
    datamanagerService: DataManagerService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private clipboard: Clipboard,
  ) {
    super();
    this.page = 1;
    this.rows = 10;
    this.service = datamanagerService;
    this.getTableSettings();
    this.searchHits = new Subject<void>();
    this.getListUrl = AppConstants.BASE_URL + AppConstants.FUNDRAISER_URL;
    this.deleteUrl = AppConstants.BASE_URL + AppConstants.FUNDRAISER_URL;
    this.columnDefinition = HeaderConstants.fundraiserListHeader;
    this.headings = 'Fundraisers';
    this.delete_permission = this.permissionCheck(
      PermissionConstants.PERMISSIONS_FUNDRAISER_REMOVE,
    );
    this.create_permission = this.permissionCheck(
      PermissionConstants.PERMISSIONS_FUNDRAISER_ADD,
    );
    this.view_permission = this.permissionCheck(
      PermissionConstants.PERMISSIONS_FUNDRAISER_VIEW,
    );
    this.edit_permission = this.permissionCheck(
      PermissionConstants.PERMISSIONS_FUNDRAISER_EDIT,
    );
    this.excel_permission = this.permissionCheck(
      PermissionConstants.PERMISSIONS_FUNDRAISER_EXCEL,
    );
    this.list_permission = this.permissionCheck(
      PermissionConstants.PERMISSIONS_FUNDRAISER_VIEW,
    );
    this.permissionsCheck = {
      view_permission: this.view_permission,
      delete_permission: this.delete_permission,
      edit_permission: this.edit_permission,
      list_permission: this.list_permission,
      create_permission: this.create_permission,
    };
  }
  ngOnInit(): void {
    this.loader = true;
    this.searchHits.pipe(debounceTime(600)).subscribe((res) => {
      this.loader = true;
      if (!this.isNullOrUndefined(this.searchText)) {
        console.log('serch jsjsdf', this.setUrl());
      }
    });
    this.setUrl();
  }

  onNotifySelected(selectedRows: object[]) {
    console.log(selectedRows);
  }
  protected getListRes(res: any): void {
    this.loader = true;
    this.rowData = [];
    if (res['status'] == 'success') {
      this.resultsLength = res.data.pagination.total;
      res.data.rowData.forEach((element: any, index: number) => {
        element.slug = '../fund/' + (element.slug ?? element.id);
      });
      this.rowData = res.data.rowData;
      console.log(this.rowData);
      this.loader = false;
    } else {
      this.loader = false;
    }
  }

  protected getListErr(err: any): void {
    console.log(err);
    this.loader = false;
  }
  getActions(data: any) {
    if (data.actions === 'delete') {
      const dialogRef = this.dialog.open(MessageDialogComponent, {
        data: {
          message: 'Do you want to delete this fundraiser?',
          buttonText: {
            ok: 'Ok',
            cancel: 'Close',
          },
        },
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          let payload = {
            ids: [data.data.id],
          };
          this.service
            .deleteRequest(this.deleteUrl + '/' + data.data.id, payload)
            .subscribe({
              next: (res: any) => {
                if (res['status'] == 'success') {
                  this.service.showSnackBar(res.message, 'ok', 3000);
                  this.router
                    .navigateByUrl('home', { skipLocationChange: true })
                    .then(() => {
                      this.router.navigate([location.pathname]);
                    });
                } else {
                  this.service.showSnackBar(res.message, 'ok', 3000);
                }
              },
              error: (error) => {
                this.service.showSnackBar(error.message, 'ok', 3000);
              },
            });
        }
      });
    } else if (data.actions == 'link') {
      const currentUrl = window.location.href;
      const segments = currentUrl.split('/');

      // Find the index where the base URL starts
      let baseIndex = 0;
      for (let i = 0; i < segments.length; i++) {
        if (segments[i] !== '..') {
          baseIndex = i;
          break;
        }
      }

      // Construct the base URL
      const baseUrl = segments.slice(0, baseIndex + 3).join('/'); // Add 3 segments for http:// or https://

      let newPath = data.data.slug.replace(/(\.\.\/)+/, '');
      const fullUrl = baseUrl + '/' + newPath;
      alert('Full URL has been copied ' + fullUrl);
      console.log(fullUrl);
      // Copy the full URL to the clipboard
      this.clipboard.copy(fullUrl);

      this.router.navigate([data.data.slug]);
    } else if (data.actions == 'edit') {
      this.router.navigate(['../fundraisers/store'], {
        queryParams: { id: data.data.id },
        relativeTo: this.route,
      });
    } else if (data.actions == 'view') {
      this.router.navigate(['../fundraisers/view', data.data.id]);
    } else {
      this.router.navigate(['./' + data.data.id], {
        relativeTo: this.route,
      });
    }
  }

  createNew() {
    this.router.navigate(['fundraisers/store']);
  }
}
