import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { interval } from 'rxjs';
import { DataManagerService } from '../services/DataManager/data-manager.service';
import { AppConstants } from '../shared/constants/app-constants';

@Component({
  selector: 'app-fundraiser-landing',
  templateUrl: './fundraiser-landing.component.html',
  styleUrls: ['./fundraiser-landing.component.css'],
  // encapsulation: ViewEncapsulation.Emulated,
})
export class FundraiserLandingComponent implements OnInit {
  service: DataManagerService;
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    dataManagerService: DataManagerService,
  ) {
    this.service = dataManagerService;
  }
  currentPage: number = 1;
  pageSize: number = 4;
  totalItems: number = 0;
  apiUrl: string;
  totalPages: number;
  funds: any;
  searchQuery: string;
  loading: boolean = false;
  ngOnInit(): void {
    this.loading = true;
    this.getFundraisers();
    this.fetchFundraisers();
  }
  fundraisers = [];
  isReadMore = false;
  toggleReadMore(event: any): void {
    event.preventDefault();
    this.isReadMore = !this.isReadMore;
  }
  fetchFundraisers(): void {
    interval(10000).subscribe(() => {
      this.getFundraisers();
    });
  }
  getFundraisers(): void {
    this.service
      .getPaginatedData(
        AppConstants.BASE_URL + '/active_fundraisers',
        this.currentPage,
        this.pageSize,
        this.searchQuery,
      )
      .subscribe(
        (data: any) => {
          this.fundraisers = data.data.rowData;
          this.totalItems = data.data.pagination.total;
          this.totalPages = data.data.pagination.lastPage;
          console.log(this.fundraisers);
        },
        (error) => {
          console.error('Error fetching fundraisers:', error);
        },
      )
      .add(() => {
        this.loading = false;
      });
  }
  calculateProgressBarWidth(fundraiser: any): string {
    const percentage =
      (fundraiser.current_amount / fundraiser.target_amount) * 100;
    if (percentage == 0) return '1' + '%';
    return percentage + '%';
  }
  scrollToSection(sectionId: string) {
    const section = document.getElementById(sectionId);
    if (section) {
      const offsetPosition = section.offsetTop - 20;

      section.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  }
  navigateToFundraiserDetails(fundraiserId: string) {
    this.router.navigate(['/fund', fundraiserId]);
  }
  onSearch(): void {
    this.currentPage = 1;
    this.loading = true;
    this.getFundraisers();
  }

  onPageChange(newPage: number): void {
    this.currentPage = newPage;
    this.loading = true;
    this.getFundraisers();
  }

  onPageSizeChange(newSize: number): void {
    this.pageSize = newSize;
    this.currentPage = 1;
    this.loading = true;
    this.getFundraisers();
  }
}
