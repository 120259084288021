import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AnonymousDonationComponent } from './anonymous-donation/anonymous-donation.component';
import { AnonymousEventRegisterComponent } from './anonymous-event-register/anonymous-event-register.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { ForgotPasswordComponent } from './component/forgot-password/forgot-password.component';
import { HomeComponent } from './component/home/home.component';
import { LoginComponent } from './component/login/login.component';
import { SignupComponent } from './component/signup/signup.component';
import { LandingComponent } from './landing/landing.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AuthGuardService } from './services/AuthGuard/auth-guard.service';
import { CheckoutComponent } from './shared/checkout/checkout.component';
import { ManageSystemDiscountComponent } from './finance/manage-system-discount/manage-system-discount.component';
import { AddSystemDiscountComponent } from './finance/add-system-discount/add-system-discount.component';
import { PaymentSuccessPageComponent } from './payment-success-page/payment-success-page.component';
import { UnsubscribeComponent } from './finance/unsubscribe/unsubscribe.component';
import { CancelMessageComponent } from './finance/cancel-message/cancel-message.component';
import { PaymentReceiptsComponent } from './payment-receipts/payment-receipts.component';
import { CoursesRegisterPageComponent } from './courses-register-page/courses-register-page.component';
import { AboutSchoolPageComponent } from './about-school-page/about-school-page.component';
import { VolunteerPageComponent } from './volunteer-page/volunteer-page.component';
import { VolunteerListComponent } from './volunteer-list/volunteer-list.component';
import { VolunteerViewComponent } from './volunteer-view/volunteer-view.component';
import { SchoolPaymentExportComponent } from './school-payment-export/school-payment-export.component';
import { CreateFundraiserComponent } from './create-fundraiser/create-fundraiser.component';
import { FundraiserListComponent } from './fundraiser-list/fundraiser-list.component';
import { FundraiserLandingComponent } from './fundraiser-landing/fundraiser-landing.component';
import { FundraiserDetailsComponent } from './fundraiser-details/fundraiser-details.component';
import { SuccessFundMessageComponent } from './success-fund-message/success-fund-message.component';
import { ViewFundraiserComponent } from './view-fundraiser/view-fundraiser.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'payment', component: PaymentSuccessPageComponent },
  { path: 'payments/receipts/:id', component: PaymentReceiptsComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'forgot', component: ForgotPasswordComponent },
  { path: 'home', component: HomeComponent },
  { path: 'donation', component: AnonymousDonationComponent },
  { path: 'payments/unsubscribe/:id', component: UnsubscribeComponent },
  { path: 'payments/unsubscribed', component: CancelMessageComponent },
  { path: 'reset-password/:id', component: ResetPasswordComponent },
  { path: 'volunteer', component: VolunteerPageComponent },
  {
    path: 'volunteers',
    component: VolunteerListComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'volunteers/:id',
    component: VolunteerViewComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'events/:event_id',
    component: AnonymousEventRegisterComponent,
  },
  {
    path: 'courses/:course_id',
    component: CoursesRegisterPageComponent,
  },
  {
    path: 'fundraisers/store',
    component: CreateFundraiserComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'fundraisers',
    component: FundraiserListComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'fundraisers/home',
    component: FundraiserLandingComponent,
  },
  {
    path: 'fund/:fund_id',
    component: FundraiserDetailsComponent,
  },
  {
    path: 'funded/:fund_id',
    component: SuccessFundMessageComponent,
  },
  {
    path: 'fundraisers/view/:fund_id',
    component: ViewFundraiserComponent,
    canActivate: [AuthGuardService],
  },

  {
    path: 'schools/about',
    component: AboutSchoolPageComponent,
  },
  {
    path: 'schools/schoolreports',
    component: SchoolPaymentExportComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'landing',
    component: LandingComponent,
    canActivate: [AuthGuardService],
  },
  { path: 'checkout', component: CheckoutComponent },
  {
    path: 'services',
    loadChildren: () =>
      import('../app/service/service.module').then((m) => m.ServiceModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'all-events',
    loadChildren: () =>
      import('../app/events/events.module').then((m) => m.EventsModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'schools',
    loadChildren: () =>
      import('../app/school/school.module').then((m) => m.SchoolModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'donations',
    loadChildren: () =>
      import('../app/donations/donations.module').then(
        (m) => m.DonationsModule,
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'expenses',
    loadChildren: () =>
      import('../app/expenses/expenses.module').then((m) => m.ExpensesModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'external',
    loadChildren: () =>
      import('../app/reports/reports.module').then((m) => m.ReportsModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'employee',
    loadChildren: () =>
      import('../app/employee/employee.module').then((m) => m.EmployeeModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'system-settings',
    loadChildren: () =>
      import('../app/settings/settings.module').then((m) => m.SettingsModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'user-permission',
    loadChildren: () =>
      import('../app/user-permission/user-permission.module').then(
        (m) => m.UserPermissionModule,
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'configurations',
    loadChildren: () =>
      import('../app/school-config/school-config.module').then(
        (m) => m.SchoolConfigModule,
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'all-discount/discount',
    component: ManageSystemDiscountComponent,
  },
  {
    path: 'add-discount',
    component: AddSystemDiscountComponent,
  },
  {
    path: 'edit-discount',
    component: AddSystemDiscountComponent,
  },
  { path: '**', redirectTo: '/login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
export const routingComponents = [
  LoginComponent,
  SignupComponent,
  DashboardComponent,
  ForgotPasswordComponent,
];
