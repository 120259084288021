import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BaseComponent } from '../shared/abstract/base.component';
import { ActivatedRoute, Router } from '@angular/router';
import { DataManagerService } from '../services/DataManager/data-manager.service';
import { AppConstants } from '../shared/constants/app-constants';

@Component({
  selector: 'app-create-fundraiser',
  templateUrl: './create-fundraiser.component.html',
  styleUrls: ['./create-fundraiser.component.css'],
})
export class CreateFundraiserComponent extends BaseComponent {
  currentStep = 1;
  formOneSubmit = false;
  formTwoSubmit = false;
  formThreeSubmit = false;

  stepOneForm: FormGroup;
  stepTwoForm: FormGroup;
  stepThreeForm: FormGroup;
  fileError: boolean;
  SelectedthumbnailImage: boolean;
  Selectedthumbnail: any;
  thumnailPreview: string;
  currentFile: File;
  parentForm: FormGroup<{
    stepOne: FormGroup<any>;
    stepTwo: FormGroup<any>;
    stepThree: FormGroup<any>;
  }>;
  createFundRaiserUrl: string = AppConstants.BASE_URL + '/fundraisers';
  formTitle: string = 'Start a FundRaiser';
  formSlug: any = null;

  constructor(
    private fb: FormBuilder,
    dataManagerService: DataManagerService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    super();
    this.buttonText = 'Submit';
    this.formLoader = true;
    this.service = dataManagerService;
    this.getFormData();
  }
  setForm() {
    this.stepOneForm = this.fb.group({
      title: [
        this.data && this.data.title ? this.data.title : '',
        Validators.required,
      ],
      description: [
        this.data && this.data.description ? this.data.description : '',
        Validators.required,
      ],
      fund_slug: [
        this.data && this.data.fund_slug ? this.data.fund_slug : '',
        [Validators.required, Validators.maxLength(40), this.slugValidator],
      ],
    });

    this.stepTwoForm = this.fb.group({
      target_amount: [
        this.data && this.data.target_amount ? this.data.target_amount : '',
        Validators.required,
      ],
      bank_account: [
        this.data && this.data.bank_account_id ? this.data.bank_account_id : '',
        Validators.required,
      ],
    });

    this.stepThreeForm = this.fb.group({
      cover_image: ['', Validators.required],
    });
    this.parentForm = this.fb.group({
      stepOne: this.stepOneForm,
      stepTwo: this.stepTwoForm,
      stepThree: this.stepThreeForm,
    });
  }
  nextStep() {
    if (this.currentStep < 3) {
      if (this.currentStep == 1) {
        this.formOneSubmit = true;
        if (this.stepOneForm.invalid) {
          return;
        }
      } else if (this.currentStep == 2) {
        this.formTwoSubmit = true;
        if (this.stepTwoForm.invalid) {
          return;
        }
      }
      this.currentStep++;
    }
  }
  previousStep() {
    if (this.currentStep > 1) {
      this.currentStep--;
    }
  }
  cardMaxWidth: number = 300;
  cardMaxHeight: number = 200;

  selectThumnailImage(event: any): void {
    this.fileError = false;
    this.SelectedthumbnailImage = true;
    this.thumnailPreview = '';
    this.Selectedthumbnail = event.target.files[0];
    console.log(this.Selectedthumbnail);
    if (this.Selectedthumbnail) {
      const file: File | null = this.Selectedthumbnail;
      if (file) {
        if (event.target.files[0].size > 5242880) {
          this.fileError = true;
        } else {
          this.currentFile = file;
          const reader = new FileReader();
          reader.onload = (e: any) => {
            this.thumnailPreview = e.target.result;
          };
          reader.readAsDataURL(this.currentFile);
        }
      }
    }
  }
  updateThumnail(data: string) {
    this.SelectedthumbnailImage = false;
    const img_url = data;
    const url = this.data.cover_image.split('/');
    this.Selectedthumbnail = new File(
      [this.data.cover_image],
      url[url.length - 1],
      {
        type:
          'image/' +
          url[url.length - 1].split(/[#?]/)[0].split('.').pop().trim(),
      },
    );
    this.thumnailPreview = img_url;
    if (this.thumnailPreview) {
      this.stepThreeForm.controls['cover_image'].clearValidators();
      this.stepThreeForm.controls['cover_image'].updateValueAndValidity();
    }
  }
  slugValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const slugPattern = /^[a-zA-Z0-9-]+$/; // Allow only letters, numbers, and hyphens

    if (control.value && !slugPattern.test(control.value)) {
      return { invalidSlug: true };
    }

    return null;
  }

  submitForm() {
    this.submitted = true;
    if (this.parentForm.valid) {
      const combinedFormValues = {
        ...this.stepOneForm.value,
        ...this.stepTwoForm.value,
        ...this.stepThreeForm.value,
      };

      console.log('Combined Form Values:', combinedFormValues);
      const formData = new FormData();
      for (let i in combinedFormValues) {
        if (i == 'cover_image' && this.SelectedthumbnailImage) {
          this.Selectedthumbnail
            ? formData.append(
                i,
                this.Selectedthumbnail,
                this.Selectedthumbnail.name,
              )
            : console.log();
        } else if (i === 'Selectedthumbnail' && !this.SelectedthumbnailImage) {
          formData.delete(i);
        } else {
          formData.append(i, combinedFormValues[i]);
        }
      }
      this.service
        .createwithFile(
          this.createFundRaiserUrl +
            (this.data?.id != null ? '/' + this.data.id : ''),
          formData,
        )
        .subscribe({
          next: (res: any) => {
            if (res['status'] == 'success') {
              this.loadFlag2 = false;
              this.service.showSnackBar(res.message, 'ok', 3000);
              this.router.navigate(['/fundraisers']);
            } else {
              this.loadFlag2 = false;
              this.topErrorMessage = res.message;
            }
          },
          error: (error) => {
            this.loadFlag2 = false;
            this.service.showSnackBar(error.message, 'ok', 3000);
          },
        });
    }
  }

  getFormData() {
    this.formLoader = true;
    this.route.queryParams.subscribe((params: any) => {
      console.log(params);
      if (params['id']) {
        this.service
          .getById(AppConstants.BASE_URL + '/fundraisers' + '/' + params['id'])
          .subscribe({
            next: (res: any) => {
              if (res['status'] == 'success') {
                if (res.data) {
                  this.data = res.data;
                  this.setForm();
                  this.formSlug = this.data.fund_slug;
                  console.log(this.data);
                  this.updateThumnail(this.data.cover_image);
                  this.formTitle = 'Update a Fundraiser';
                }
                this.buttonText = 'Update';
                this.formLoader = false;
              } else {
                this.formLoader = false;
                this.service.showSnackBar(res.message, 'ok', 3000);
              }
            },
            error: (error) => {
              this.formLoader = false;
              this.service.showSnackBar(error.message, 'ok', 3000);
            },
          });
      } else {
        this.formLoader = false;
      }
    });
    this.getBankAccounts();
    this.setForm();
  }
  getBankAccounts() {
    this.formLoader = true;
    const getListUrl = AppConstants.BASE_URL + '/account-numbers';
    this.service.getAllList(getListUrl).subscribe({
      next: (res: any) => {
        if (res['status'] == 'success') {
          this.expenseAccountList = res.data;
          this.formLoader = false;
        } else {
          this.service.showSnackBar(res.message, 'ok', 3000);
        }
      },
      error: (error) => {
        this.formLoader = false;
        this.service.showSnackBar(error.message, 'ok', 3000);
      },
    });
  }
}
