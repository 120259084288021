import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataManagerService } from '../services/DataManager/data-manager.service';
import { BaseComponent } from '../shared/abstract/base.component';
import { AppConstants } from 'src/app/shared/constants/app-constants';

@Component({
  selector: 'app-volunteer-view',
  templateUrl: './volunteer-view.component.html',
  styleUrls: ['./volunteer-view.component.css'],
})
export class VolunteerViewComponent extends BaseComponent implements OnInit {
  url: string = AppConstants.BASE_URL + AppConstants.VOLUNTEER_URL;

  constructor(
    dataManagerService: DataManagerService,
    private route: ActivatedRoute,
  ) {
    super();
    this.service = dataManagerService;
    this.headings = 'Volunteers';
    this.getFormData();
  }

  ngOnInit(): void {}

  getFormData() {
    this.formLoader = true;
    this.route.params.subscribe((params) => {
      if (params['id']) {
        this.service.getById(this.url + '/' + params['id']).subscribe({
          next: (res: any) => {
            if (res['status'] == 'success') {
              if (res.data) {
                this.data = res.data;
                console.log(this.data);
              }
              this.formLoader = false;
            } else {
              this.formLoader = false;
              this.service.showSnackBar(res.message, 'ok', 3000);
            }
          },
          error: (error) => {
            this.formLoader = false;
            this.service.showSnackBar(error.message, 'ok', 3000);
          },
        });
      } else {
        this.formLoader = false;
      }
    });
  }
}
