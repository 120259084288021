import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from '../../shared/constants/app-constants';
import { BaseComponent } from '../../shared/abstract/base.component';
import { DataManagerService } from '../../services/DataManager/data-manager.service';
import { VERSION } from '../../../environments/version';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent extends BaseComponent implements OnInit {
  public version_info = VERSION;
  public loginForm!: FormGroup;
  public loginUrl = AppConstants.BASE_URL + AppConstants.LOGIN_URL;
  public menuList: any;
  public errorMessage: string = '';
  url: string;
  returnUrl: string;
  constructor(
    private formBuilder: FormBuilder,
    private _router: Router,
    private route: ActivatedRoute,
    private dataManagerService: DataManagerService,
  ) {
    super();
    this.autoLogin();
    this.url = this._router['url'];
    const regex = /(?:\?|&)message=([^&]+)/;
    const match = this.url.match(regex);
    this.route.queryParams.subscribe((params) => {
      // Retrieve the returnUrl from query parameters and decode it
      this.returnUrl = decodeURIComponent(params['returnUrl'] || '/');
    });
    console.log(this.returnUrl);

    if (match && match[1]) {
      const message = decodeURIComponent(match[1]); // Decoding URI component
      this.errorMessage = message ?? '';
    } else {
      console.log('Message parameter not found in the URL text.');
    }
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }
  get loginFormControl() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    } else {
      this.loadFlag2 = true;
      this.dataManagerService
        .userLogin(this.loginUrl, this.loginForm.value)
        .subscribe({
          next: (res: any) => {
            if (res['status'] == 'success') {
              this.loadFlag2 = false;
              localStorage.setItem('authToken', JSON.stringify(res.token));
              localStorage.setItem('user', JSON.stringify(res.data));
              localStorage.setItem(
                AppConstants.STORAGE_KEY_USER_PERMISSIONS,
                JSON.stringify(res.data.permissions),
              );
              this.dataManagerService.showSnackBar(res.message, 'ok', 3000);
              this.dataManagerService.setLoginStatus(true);
              this.dataManagerService.permissionsArray = res.data.permissions;
              this.dataManagerService.menuList = res.data.menu;
              var dashboardPermission = this.dataManagerService.menuList.some(
                (menu: any) => {
                  return menu.name == 'Dashboard';
                },
              );
              if (dashboardPermission && this.returnUrl == '/') {
                this._router
                  .navigateByUrl('home', { skipLocationChange: true })
                  .then(() => {
                    this._router.navigate(['/dashboard']);
                  });
              } else if (this.returnUrl) {
                this._router.navigate([this.returnUrl]);
              } else {
                this._router
                  .navigateByUrl('home', { skipLocationChange: true })
                  .then(() => {
                    this._router.navigate(['/landing']);
                  });
              }
              // if (res.data.gid === 3) {
              //   this._router.navigateByUrl('home', { skipLocationChange: true }).then(() => {
              //     this._router.navigate(['/landing']);
              //   });
              // } else {
              //   this._router.navigateByUrl('home', { skipLocationChange: true }).then(() => {
              //     this._router.navigate(['/dashboard']);
              //   });
              // }
            } else {
              this.loadFlag2 = false;
              this.errorMessage = res.message;
              this.dataManagerService.showSnackBar(res.message, 'ok', 3000);
            }
          },
          error: (error) => {
            this.loadFlag2 = false;
            this.dataManagerService.showSnackBar(error.message, 'ok', 3000);
          },
        });
    }
  }
  gotoForgot() {
    this._router.navigate(['/forgot']);
  }
  gotoDonate() {}
  autoLogin() {
    let loged_user = JSON.parse(localStorage.getItem('user') || 'null');
    const logined_Status = this.dataManagerService.LoginStatus();
    if (logined_Status) {
      this.menuList = JSON.parse(localStorage.getItem('user') || 'null');
      var dashboardPermission = this.menuList.menu.some((menu: any) => {
        return menu.name == 'Dashboard';
      });
      if (dashboardPermission) {
        this._router
          .navigateByUrl('home', { skipLocationChange: true })
          .then(() => {
            this._router.navigate(['/dashboard']);
          });
      } else {
        this._router
          .navigateByUrl('home', { skipLocationChange: true })
          .then(() => {
            this._router.navigate(['/landing']);
          });
      }
    } else {
      // this._router.navigate(['/login']);
    }
  }
}
