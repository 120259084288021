<div [ngClass]="toggleIframe()?'container': ''">
    <div [ngClass]="toggleIframe() ? 'login-logo':'hide' ">
        <img src="https://nsicc.ca/wp-content/uploads/2023/01/Volunteers-Header.png" alt=" Login Logo">
    </div>

    <div class="card">
        <mat-toolbar class="main-header">
            <mat-toolbar-row>
                <span>{{ headings }}</span>
                <span class="example-spacer"></span>

            </mat-toolbar-row>
        </mat-toolbar>
        <div class="card-body">
            <div class="form-ajax-box">

                <div class="row" *ngIf="!topErrorMessage">
                    <div class="col-md-12 error">
                        {{errorMsg}}
                    </div>
                </div>
                <form class="form-horizontal nsicc-form validated" [formGroup]="volunteerForm">
                    <div class="row">
                        <div class="col-12"></div>
                        <p class="hadith">
                            عَنِ النبي ﷺ قَالَ:
                            ( مَنْ نَفَّسَ عَنْ مُؤمِن كُربَةً مِن كُرَبِ الدُّنيَا نَفَّسَ اللهُ عَنهُ كُربَةً مِنْ
                            كرَبِ يَوم القيامَةِ )
                        </p>
                    </div>
                    <div class="row">
                        <label class="form-label mb-4" for="donation_type">Contact Information
                        </label>
                        <div class="form-group  col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6">
                            <mat-form-field appearance='outline' class="form-full-width">
                                <mat-label for="name">Name
                                </mat-label>
                                <input matInput type="text" name="name" id="name" formControlName="name" maxlength="20"
                                    (keypress)="letterOnly($event)">
                            </mat-form-field>
                            <div *ngIf="submitted && volunteerFormControls.name.errors" class="invalid-feedback">
                                <div *ngIf="volunteerFormControls.name?.errors?.required">Name
                                    is
                                    required.
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-12 col-lg-4 col-sm-6 col-md-6 col-xl-4">
                            <mat-form-field appearance='outline' class="w-100">
                                <mat-label>Gender</mat-label>
                                <mat-select formControlName="gender" id="gender"
                                    [ngClass]="{ 'is-invalid': submitted && volunteerFormControls.gender.errors }"
                                    data-parsley-multiple="gender">
                                    <mat-option value="-" disabled>Select Gender</mat-option>
                                    <mat-option value="1">Male</mat-option>
                                    <mat-option value="0">Female</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div *ngIf="submitted && volunteerFormControls.gender.errors" class="invalid-feedback">
                                <div *ngIf="volunteerFormControls.gender.errors?.required">Gender is required.</div>
                            </div>
                        </div>

                    </div>
                    <div class="row">


                        <div class="form-group col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6">
                            <mat-form-field appearance='outline' class="form-full-width">
                                <mat-label for="email"> Email
                                </mat-label>
                                <input matInput type="text" name="email" id="email" formControlName="email"
                                    pattern="^\w+([\.-]?\w+)*(\+[a-z0-9-]+)?@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                                    [ngClass]="{ 'is-invalid': submitted && volunteerFormControls.email.errors }">
                            </mat-form-field>
                            <div *ngIf="submitted && volunteerFormControls.email.errors" class="invalid-feedback">
                                <div *ngIf="volunteerFormControls.email.errors?.required">Email is
                                    required.
                                </div>
                                <div *ngIf="volunteerFormControls.email.errors.pattern">Invaild
                                    email.
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6">

                            <span
                                class="numberone input-group-addon last-d col-2 col-lg-2 col-xl-2 col-md-2 col-sm-2 text-center pl-2 pr-2 ">+1</span>
                            <mat-form-field appearance='outline'
                                class="form-full-width phone col-10 col-lg-10 col-xl-10 col-sm-10  col-md-10">
                                <mat-label>Phone Number </mat-label>
                                <input matInput placeholder="(000) 000-0000" type="tel" appPhoneMask maxlength="14"
                                    autocomplete="off" name="phone" formControlName="contact_number" id="phone"
                                    [ngClass]="{ 'is-invalid': submitted && volunteerFormControls.contact_number.errors }">
                            </mat-form-field>
                            <div *ngIf="submitted && volunteerFormControls.contact_number.errors"
                                class="invalid-feedback">
                                <div style="margin-top:-10px !important"
                                    *ngIf="volunteerFormControls.contact_number.errors.required">Phone Number is
                                    required.
                                </div>
                                <div *ngIf="volunteerFormControls.contact_number.errors.pattern">Invalid
                                    contact
                                    number.
                                </div>
                            </div>

                        </div>

                    </div>
                    <div class="row">

                        <div class="form-group col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6">
                            <mat-form-field appearance='outline' class="w-100">
                                <mat-label>Preferred method of contact</mat-label>
                                <mat-select formControlName="contact_method"
                                    [ngClass]="{ 'is-invalid': submitted && volunteerFormControls.contact_method.errors }"
                                    data-parsley-multiple="contact_method">
                                    <mat-option value="-" disabled>Select contact method</mat-option>
                                    <mat-option value="1">Phone</mat-option>
                                    <mat-option value="0">Email</mat-option>
                                    <mat-option value="2">Both</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div *ngIf="submitted && volunteerFormControls.contact_method.errors"
                                class="invalid-feedback">
                                <div *ngIf="volunteerFormControls.contact_method.errors?.required">Contact Method is
                                    required.</div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <label class="form-label mb-4" for="donation_type">Availability Information
                        </label>
                        <div class="form-group col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6">
                            <mat-form-field appearance='outline' class="w-100">
                                <mat-label>Availability Days</mat-label>
                                <mat-select formControlName="availabilityDays"
                                    [ngClass]="{ 'is-invalid': submitted && volunteerFormControls.availabilityDays.errors }"
                                    multiple>
                                    <mat-select-trigger>
                                        {{selectedDaysNames() }}
                                    </mat-select-trigger>
                                    <mat-option *ngFor="let day of days" [value]="day.value">
                                        {{ day.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div *ngIf="submitted && volunteerFormControls.availabilityDays.errors"
                                class="invalid-feedback">
                                <div *ngIf="volunteerFormControls.availabilityDays.errors?.required">Availability Days
                                    are required.</div>
                            </div>
                        </div>
                        <div class="form-group col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6">
                            <mat-form-field appearance='outline' class="w-100">
                                <mat-label>Weekly Hours Availability</mat-label>
                                <mat-select formControlName="weeklyHoursAvailability"
                                    [ngClass]="{ 'is-invalid': submitted && volunteerFormControls.weeklyHoursAvailability.errors }">
                                    <mat-option value="" disabled>Select weekly hours availability</mat-option>
                                    <mat-option *ngFor="let option of weeklyHoursAvailabilityOptions"
                                        [value]="option.value">
                                        {{ option.viewValue }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div *ngIf="submitted && volunteerFormControls.weeklyHoursAvailability.errors"
                                class="invalid-feedback">
                                <div *ngIf="volunteerFormControls.weeklyHoursAvailability.errors?.required">Weekly Hours
                                    Availability is required.</div>
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <label class="form-label mb-4" for="info">Skills & Experience
                        </label>
                        <div class="form-group  col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6">
                            <mat-form-field appearance='outline' class="form-full-width">
                                <mat-label for="name">Career
                                </mat-label>
                                <input matInput type="text" name="career" id="career" formControlName="career"
                                    maxlength="25" placeholder="Enter your career or profession if possible"
                                    (keypress)="letterOnly($event)">
                            </mat-form-field>

                        </div>
                        <div class="form-group  col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6">
                            <mat-form-field appearance='outline' class="form-full-width">
                                <mat-label for="Education">Education
                                </mat-label>
                                <input matInput type="text" name="education" id="education" formControlName="education"
                                    maxlength="30" placeholder="Enter your highest level of education"
                                    (keypress)="letterOnly($event)">
                            </mat-form-field>
                            <div *ngIf="submitted && volunteerFormControls.education.errors" class="invalid-feedback">
                                <div *ngIf="volunteerFormControls.education.errors?.required">Education Level is
                                    required.</div>
                            </div>

                        </div>

                        <div class="form-group col-12 col-lg-6 col-sm-6 col-md-6 col-xl-6">
                            <mat-form-field appearance='outline' class="w-100">
                                <mat-label>Skill Set and Interests </mat-label>
                                <mat-select formControlName="skillSetInterests"
                                    [ngClass]="{ 'is-invalid': submitted && volunteerFormControls.skillSetInterests.errors }"
                                    multiple>
                                    <mat-select-trigger>
                                        {{ selectedOptionsNames() }}
                                    </mat-select-trigger>
                                    <mat-option *ngFor="let option of skillSetInterestsOptions" [value]="option.id">
                                        {{ option.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div *ngIf="submitted && volunteerFormControls.skillSetInterests.errors"
                                class="invalid-feedback">
                                <div *ngIf="volunteerFormControls.skillSetInterests.errors?.required">Skill Set and
                                    Interests are required.</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">

                        <div class="form-group col-12">
                            <label class="form-label">Do you have vulnerable sector check and child abuse register
                                check? <span class="asterix">*</span></label>
                            <mat-radio-group formControlName="backgroundCheck">
                                <label class="form-check-label ml-2 mr-5" for="backgroundCheck-1">

                                    <input type="radio" formControlName="backgroundCheck" name="backgroundCheck"
                                        value="1" class="filled-in form-check-input" id="backgroundCheck-1"
                                        [ngClass]="{ 'is-invalid': submitted && volunteerFormControls.backgroundCheck.errors }"
                                        data-parsley-multiple="has_tax"><label class="form-label" for="has_tax-1">Yes
                                    </label>

                                </label> <label class="form-check-label ml-2 mr-5" for="backgroundCheck-0">

                                    <input type="radio" formControlName="backgroundCheck" name="backgroundCheck"
                                        value="0" class="filled-in form-check-input" id="backgroundCheck-0"
                                        [ngClass]="{ 'is-invalid': submitted && volunteerFormControls.backgroundCheck.errors }"
                                        data-parsley-multiple="backgroundCheck"><label class="form-label"
                                        for="backgroundCheck-0">No
                                    </label>

                                </label>
                            </mat-radio-group>
                            <div *ngIf="submitted && volunteerFormControls.backgroundCheck.errors"
                                class="invalid-feedback">
                                <div *ngIf="volunteerFormControls.backgroundCheck.errors?.required">Please Answer the
                                    Question
                                    .</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="container-fluid py-3">

                            <div class="row mt-3">
                                <div class="col-sm-12 mb-2">
                                    <re-captcha id="recaptcha" name="recaptcha" [(ngModel)]="token" required
                                        formControlName="recaptcha"
                                        [class.is-invalid]="submitted && volunteerFormControls.recaptcha.errors ">
                                    </re-captcha>
                                    <div *ngIf="submitted&&volunteerFormControls.recaptcha.errors?.required"
                                        class="invalid-feedback">
                                        <div *ngIf="volunteerFormControls.recaptcha.errors?.required">reCaptcha is
                                            required.</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="row">
                        <p class="invalid-feedback">{{ formMessage }}</p>
                        <div class="btn-group mr-2">
                            <button (click)="onSubmit()" class="btn btn-primary" name="save">Send
                                <i *ngIf="loadFlag2" class="fa fa-spinner fa-pulse loader mt-1 ml-1"></i></button>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>