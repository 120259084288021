import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DataManagerService } from '../services/DataManager/data-manager.service';
import { BaseComponent } from '../shared/abstract/base.component';
import { AppConstants } from '../shared/constants/app-constants';
import { AlertDialogComponent } from '../dialogs/alert-dialog/alert-dialog.component';
import { Title } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-anonymous-event-register',
  templateUrl: './anonymous-event-register.component.html',
  styleUrls: ['./anonymous-event-register.component.css'],
})
export class AnonymousEventRegisterComponent
  extends BaseComponent
  implements OnInit
{
  public eventRegisterForm: FormGroup;
  public loged_user = JSON.parse(localStorage.getItem('user') || 'null');
  public eventRegisterUrl: string;
  public getDiscount: string;

  public getEvent: string;
  id: any;
  event_cost_type: any;
  public discount_url: string = AppConstants.BASE_URL + '/discounts/all';

  eventDateList: Array<any> = [];
  public min: any;
  public max: any;
  errorMsg: any;
  event_id: any;
  urlParam: any;
  totalPrice: number;
  imageUrl: any;
  participantNames: any;
  activeDate: boolean = true;
  participantData: never[];

  safeHtml: any;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    dataManagerService: DataManagerService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private title: Title,
    private meta: Meta,
    private sanitizer: DomSanitizer,
  ) {
    super();
    this.service = dataManagerService;
    this.eventRegisterUrl =
      AppConstants.BASE_URL + '/anonymous_event_participant';
    this.getEvent =
      AppConstants.BASE_URL + AppConstants.ANONYMOUS_EVENT_REGISTER;
    this.getDiscount = AppConstants.BASE_URL + '/calculate/discounts';
    this.route.params.subscribe((params) => {
      if (params) {
        this.urlParam = params;
      }
    });
    this.min = 0;
    this.max = 120;
    this.getFormData();
  }
  ngOnInit(): void {
    this.setEventRegisterForm();
  }
  get eventRegisterFormControl() {
    return this.eventRegisterForm.controls;
  }
  setEventRegisterForm() {
    this.eventRegisterForm = this.formBuilder.group({
      full_name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      contact_number: ['', [Validators.required]],
      gender: [''],
      payment_method: ['', [Validators.required]],
      agree_to: [1],
      quantity: [1, [Validators.required]],
      event_id: this.formBuilder.array([], [Validators.required]),
      age: [''],
      additional_information: [''],
      participantNames: this.formBuilder.array([]),
    });
    if (this.event_cost_type == 1) {
      this.eventRegisterForm.controls['payment_method'].clearValidators();
      this.eventRegisterForm.controls[
        'payment_method'
      ].updateValueAndValidity();
    }
  }
  generateParticipantControls(quantity: number): void {
    const existingParticipantData = this.participantData || []; // Preserve existing data

    this.participantNames = this.formBuilder.array([]);
    quantity = Math.min(quantity, this.data.registration_capacity);
    const genderValidators =
      this.data.has_gender == 1 ? [Validators.required] : [];

    for (let i = 1; i < quantity; i++) {
      const participantGroup = this.formBuilder.group({
        name: this.formBuilder.control('', [
          Validators.required,
          this.duplicateNameValidator(),
        ]),
        relation: this.formBuilder.control('', [Validators.required]),
        gender: this.formBuilder.control('', genderValidators),
      });

      const existingData = existingParticipantData[i] || {};
      participantGroup.patchValue(existingData);

      this.participantNames.push(participantGroup);
    }

    this.participantData = this.participantNames.value; // Update participantData with the new values

    // Update the form group with the new participantNames array
    this.eventRegisterForm.setControl(
      'participantNames',
      this.participantNames,
    );
  }

  duplicateNameValidator(): (
    control: AbstractControl,
  ) => { [key: string]: boolean } | null {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (!this.participantNames || this.participantNames.length === 0) {
        return null; // No duplicates if there are no names yet
      }

      const currentName = control.value;

      const duplicate = this.participantNames.value.some(
        (nameControl: { value: any }) => nameControl === currentName,
      );

      return duplicate ? { duplicateName: true } : null;
    };
  }
  onSubmit() {
    this.submitted = true;
    console.log(this, this.eventRegisterForm.value);
    if (this.eventRegisterForm.invalid) {
      return;
    } else {
      console.log(this.eventRegisterForm.value);
      this.loadFlag2 = true;
      this.eventRegisterForm.value.event_id = [
        ...new Set(this.eventRegisterForm.value.event_id),
      ];
      this.eventRegisterForm.value.agree_to =
        this.eventRegisterForm.value.agree_to == false
          ? 0
          : this.eventRegisterForm.value.agree_to;
      this.eventRegisterForm.value.contact_number =
        this.eventRegisterForm.value.contact_number.replace(/\D+/g, '');
      this.service
        .create(this.eventRegisterUrl, this.eventRegisterForm.value)
        .subscribe({
          next: (res: any) => {
            if (res['status'] == 'success' && res.data) {
              this.loadFlag2 = false;
              this.router.navigate(['../../checkout'], {
                queryParams: {
                  id: res.data.payable_id,
                  type: 'anonymous-event-register',
                  event_type: this.data.event_type,
                  event_id: this.data.id,
                },
                relativeTo: this.route,
              });
            } else if (res['status'] == 'success' && !res.data) {
              this.loadFlag2 = false;
              this.router.navigate(['/login']);
              if (res.message) {
                const dialogRef = this.dialog.open(AlertDialogComponent, {
                  data: {
                    message: res.message,
                    buttonText: {
                      ok: 'Ok',
                      cancel: 'Close',
                    },
                  },
                });
                dialogRef.afterClosed().subscribe((confirmed: boolean) => {
                  if (confirmed) {
                  }
                });
              }
            } else {
              if (typeof res.message == 'string') {
                this.loadFlag2 = false;
                if (res.message) {
                  const dialogRef = this.dialog.open(AlertDialogComponent, {
                    data: {
                      message: res.message,
                      buttonText: {
                        ok: 'Ok',
                        cancel: 'Close',
                      },
                    },
                  });
                  dialogRef.afterClosed().subscribe((confirmed: boolean) => {
                    if (confirmed) {
                    }
                  });
                }
              } else {
                this.loadFlag2 = false;
                this.topErrorMessage = res.message;
                this.keys = Object.keys(this.topErrorMessage);
                this.keys1 = Object.values(this.topErrorMessage);
                let i;
                let j;
                const name = [
                  'full_name',
                  'email',
                  'contact_number',
                  'age',
                  'payment_method',
                  'agree_to',
                  'event_id',
                ];
                for (i = 0; i < this.keys.length; i++) {
                  for (j = 0; j < name.length; j++) {
                    if (this.keys[i].match(name[j])) {
                      const documents = document.getElementById(name[j]);
                      if (documents) {
                        documents.style.borderColor = '#a94442';
                        documents.style.display = 'block';
                      }
                    }
                  }
                }
              }
            }
          },
          error: (error) => {
            this.loadFlag2 = false;
            if (error.message) {
              const dialogRef = this.dialog.open(AlertDialogComponent, {
                data: {
                  message: error.message,
                  buttonText: {
                    ok: 'Ok',
                    cancel: 'Close',
                  },
                },
              });
              dialogRef.afterClosed().subscribe((confirmed: boolean) => {
                if (confirmed) {
                }
              });
            }
          },
        });
    }
  }
  cancel() {
    this.router.navigate(['/login']);
  }

  getFormData() {
    this.formLoader = true;

    if (this.urlParam) {
      this.service
        .getById(this.getEvent + '/' + this.urlParam['event_id'])
        .subscribe({
          next: (res: any) => {
            if (res['status'] == 'success') {
              if (res.data) {
                this.data = res?.data;
                if (this.data.has_gender == 1) {
                  this.eventRegisterForm.controls['gender'].setValidators([
                    Validators.required,
                  ]);
                }
                const apiTitle =
                  'NSICC' +
                  ' ' +
                  this.data.event_title +
                  ' ' +
                  'Event Registration ';
                this.title.setTitle(apiTitle);

                const description = this.data.short_description; // Your website description
                const imageUrl = this.data.event_cover_img;
                this.imageUrl = imageUrl;
                this.meta.updateTag({
                  property: 'og:title',
                  content: apiTitle,
                });
                this.meta.updateTag({
                  property: 'og:description',
                  content: description,
                });
                this.meta.updateTag({
                  property: 'og:image',
                  content: imageUrl,
                });
                this.meta.updateTag({
                  name: 'twitter:title',
                  content: apiTitle,
                });
                this.meta.updateTag({
                  name: 'twitter:description',
                  content: description,
                });
                this.meta.updateTag({
                  name: 'instgram',
                  content: description,
                });
                this.meta.updateTag({
                  name: 'whatsapp',
                  content: description,
                });
                this.meta.updateTag({
                  name: 'twitter:image',
                  content: imageUrl,
                });
                this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(
                  this.data?.long_description,
                );

                if (this.data.event_dates.length > 1) {
                  this.data.event_dates.splice(0, 0, {
                    id: 'All',
                    event_date: 'All',
                    event_end_date: '',
                    checked: false,
                  });
                }
                this.data.event_dates.forEach((dateField: any) => {
                  dateField.checked = false;
                  this.eventDateList.push(dateField);
                });
                this.activeDate = this.eventDateList.length >= 1;
              }
              this.event_cost_type = this.data.event_cost_type;
              this.setEventRegisterForm();
              if (
                !this.isNullOrUndefined(this.data.max_age) &&
                !this.isNullOrUndefined(this.data.min_age) &&
                this.data.max_age != 0 &&
                !this.isNullOrUndefined(this.data.min_age_required) &&
                this.data.min_age_required == 1
              ) {
                this.eventRegisterForm.controls['age'].setValidators([
                  Validators.required,
                  Validators.min(this.data.min_age),
                  Validators.max(this.data.max_age),
                ]);
                this.eventRegisterForm.controls['age'].updateValueAndValidity();
                this.min = this.data.min_age;
                this.max = this.data.max_age;
              }
              this.totalPrice =
                this.data.event_cost * this.eventRegisterForm.value.quantity;
              this.changeTotal();

              this.formLoader = false;
              this.headings = 'Register Event';
              this.buttonText = 'Save';
            } else {
              this.formLoader = false;
              this.service.showSnackBar(res.message, 'ok', 3000);
            }
          },
          error: (error) => {
            this.formLoader = false;
            this.service.showSnackBar(error.message, 'ok', 3000);
          },
        });
    } else {
      this.formLoader = false;
    }
  }
  onCheckboxChange(e: any, data: any) {
    const selectedevent_id: FormArray = this.eventRegisterForm.get(
      'event_id',
    ) as FormArray;
    if (data != 'All') {
      if (e.target.checked) {
        selectedevent_id.push(new FormControl(e.target.value.toString()));
      } else {
        this.eventDateList.forEach((dateField: any) => {
          if (
            dateField.id == 'All' ||
            e.target.value.toString() == dateField.id.toString()
          ) {
            dateField.checked = false;
          }
        });
        const index = selectedevent_id.controls.findIndex(
          (selectedevent_id) =>
            selectedevent_id.value === e.target.value.toString(),
        );
        selectedevent_id.removeAt(index);
      }
    } else {
      if (e.target.checked) {
        this.eventDateList.forEach((dateField: any) => {
          dateField.checked = true;
          if (dateField.id != 'All') {
            if (selectedevent_id.value.indexOf(dateField.id.toString()) <= -1) {
              selectedevent_id.push(new FormControl(dateField.id.toString()));
            }
          }
        });
      } else {
        this.eventDateList.forEach((dateField: any) => {
          if (dateField.id != 'All') {
            dateField.checked = false;
            const index = selectedevent_id.controls.findIndex(
              (selectedevent_id) =>
                selectedevent_id.value === dateField.id.toString(),
            );
            selectedevent_id.removeAt(index);
          }
        });
      }
    }
  }
  changeName(data: string) {
    if (data === 'Online') {
      this.buttonText = 'Proceed To Checkout';
    } else {
      this.buttonText = 'Save';
    }
  }
  changeTotal() {
    const maxQuantity = this.data.registration_capacity ?? 10;
    if (this.eventRegisterFormControl.quantity.value > maxQuantity) {
      this.eventRegisterFormControl.quantity.setValue(maxQuantity);
    }
    let formData = new FormData();
    formData.append('price', this.data.event_cost);
    formData.append('quantity', this.eventRegisterForm.value.quantity);

    this.service
      .calculateDiscount(
        this.getDiscount + '/' + this.data.discount_id ?? 0,
        formData,
      )
      .subscribe({
        next: (res: any) => {
          if (res['status'] == 'success' && res.data) {
            this.totalPrice = res.data.totalAfterDiscount;
          }
        },
      });
    const quantity = this.eventRegisterForm.get('quantity')?.value;
    this.generateParticipantControls(quantity);
  }
  isAccordionInvalid(): any {
    return this.submitted && this.participantNames.status == 'INVALID';
  }
}
