import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DataManagerService } from '../../services/DataManager/data-manager.service';
import { BaseComponent } from '../../shared/abstract/base.component';
import { AppConstants } from '../../shared/constants/app-constants';
import { MustMatch } from '../../shared/constants/custom.validation';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
})
export class SignupComponent extends BaseComponent implements OnInit {
  public signupForm!: FormGroup;
  public signUpUrl = AppConstants.BASE_URL + AppConstants.SIGNUP_URL;
  errorMsg = '';
  constructor(
    private formBuilder: FormBuilder,
    private _router: Router,
    private route: ActivatedRoute,
    private dataManagerService: DataManagerService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.signupForm = this.formBuilder.group(
      {
        // username: ['', [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]],
        firstname: [
          '',
          [Validators.required, Validators.pattern("^[a-zA-Z -']+")],
        ],
        lastname: [
          '',
          [Validators.required, Validators.pattern("^[a-zA-Z -']+")],
        ],
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required]],
        confirm_password: ['', [Validators.required]],
        mobile: true,
      },
      {
        validator: MustMatch('password', 'confirm_password'),
      },
    );
  }
  get signupFormControl() {
    return this.signupForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.signupForm.invalid) {
      console.log(this.signupForm);
      return;
    } else {
      this.loadFlag2 = true;
      this.dataManagerService
        .userSignup(this.signUpUrl, this.signupForm.value)
        .subscribe({
          next: (res: any) => {
            if (res['status'] == 'success') {
              this.loadFlag2 = false;
              this.dataManagerService.showSnackBar(res.message, 'ok', 3000);
              const message = res.message ?? '';
              this._router.navigate(['/login'], {
                queryParams: {
                  message: message,
                },
                relativeTo: this.route,
              });
            } else {
              this.loadFlag2 = false;
              this.topErrorMessage = res.message;
              this.keys = Object.keys(this.topErrorMessage);
              this.keys1 = Object.values(this.topErrorMessage);
              let i;
              let j;
              const name = [
                'firstname',
                'lastname',
                'email',
                'password',
                'confirm_password',
              ];
              for (i = 0; i < this.keys.length; i++) {
                for (j = 0; j < name.length; j++) {
                  if (this.keys[i].match(name[j])) {
                    const documents = document.getElementById(name[j]);
                    if (documents) {
                      documents.style.borderColor = '#a94442';
                      documents.style.display = 'block';
                    }
                  }
                }
              }
            }
          },
          error: (error) => {
            this.loadFlag2 = false;
            this.dataManagerService.showSnackBar(error.message, 'ok', 3000);
          },
        });
    }
  }
  gotoForgot() {
    this._router.navigate(['/forgot']);
  }
  gotoDonate() {}
}
